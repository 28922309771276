import React, { useState } from 'react';
import { Building2 } from 'lucide-react';
import { LoadingOverlay } from './LoadingOverlay';
import toast from 'react-hot-toast';
import { useImageGeneration } from '../hooks/useImageGeneration';

interface DetailedCityscapeFormProps {
  onImageGenerated: (imageUrl: string) => void;
  setIsLoading: (loading: boolean) => void;
}

const CITY_TYPES = [
  'Modern Metropolis',
  'Historic Town',
  'Art Deco District',
  'Gothic Quarter',
  'Futuristic City'
] as const;

const PERSPECTIVE_OPTIONS = [
  'Street Level',
  'Bird\'s Eye View',
  'Panoramic',
  'Skyline View'
] as const;

const FEATURE_SUGGESTIONS = [
  'towering skyscrapers and busy streets',
  'ornate buildings with detailed facades',
  'historic architecture and cobblestone roads',
  'futuristic buildings with geometric patterns',
  'gothic spires and decorative stonework'
] as const;

export function DetailedCityscapeForm({ onImageGenerated, setIsLoading }: DetailedCityscapeFormProps) {
  const [cityType, setCityType] = useState<string>(CITY_TYPES[0]);
  const [perspective, setPerspective] = useState<string>(PERSPECTIVE_OPTIONS[0]);
  const [features, setFeatures] = useState('');
  const [isGenerating, setIsGenerating] = useState(false);
  const { generateWithCredits } = useImageGeneration();

  const handleGenerateImage = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!features.trim()) {
      toast.error('Please describe the architectural features');
      return;
    }

    setIsGenerating(true);
    setIsLoading(true);
    try {
      const prompt = `Create a detailed black and white line art illustration of a ${cityType} from a ${perspective.toLowerCase()} perspective, featuring ${features}. The design should be highly detailed with intricate architectural elements, perfect for adult coloring books. Include:
      - Complex architectural details and ornate decorations
      - Clear, bold outlines for easy coloring
      - Various textures and patterns in the architecture
      - Multiple layers of depth and detail
      - Clean white background
      Make it sophisticated and engaging, suitable for advanced coloring enthusiasts.`;
      
      const imageUrl = await generateWithCredits(prompt);
      if (imageUrl) {
        onImageGenerated(imageUrl);
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('Failed to generate image');
    } finally {
      setIsGenerating(false);
      setIsLoading(false);
    }
  };

  return (
    <>
      {isGenerating && <LoadingOverlay />}
      <form onSubmit={handleGenerateImage} className="space-y-6">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            City Type
          </label>
          <div className="grid grid-cols-2 sm:grid-cols-3 gap-2">
            {CITY_TYPES.map((type) => (
              <button
                key={type}
                type="button"
                onClick={() => setCityType(type)}
                className={`px-4 py-2 rounded-lg text-sm font-medium transition-colors ${
                  cityType === type
                    ? 'bg-purple-100 text-purple-700 border-2 border-purple-500'
                    : 'bg-gray-50 text-gray-700 border border-gray-300 hover:bg-gray-100'
                }`}
              >
                {type}
              </button>
            ))}
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Perspective
          </label>
          <div className="grid grid-cols-2 gap-2">
            {PERSPECTIVE_OPTIONS.map((option) => (
              <button
                key={option}
                type="button"
                onClick={() => setPerspective(option)}
                className={`px-4 py-2 rounded-lg text-sm font-medium transition-colors ${
                  perspective === option
                    ? 'bg-purple-100 text-purple-700 border-2 border-purple-500'
                    : 'bg-gray-50 text-gray-700 border border-gray-300 hover:bg-gray-100'
                }`}
              >
                {option}
              </button>
            ))}
          </div>
        </div>

        <div>
          <label htmlFor="features" className="block text-sm font-medium text-gray-700 mb-1">
            Architectural Features
          </label>
          <textarea
            id="features"
            value={features}
            onChange={(e) => setFeatures(e.target.value)}
            placeholder="Describe the architectural features you'd like to see..."
            className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-purple-500 focus:border-purple-500 min-h-[100px] resize-y"
            required
          />
          <div className="mt-2">
            <p className="text-sm text-gray-600 mb-2">Suggestions:</p>
            <div className="flex flex-wrap gap-2">
              {FEATURE_SUGGESTIONS.map((suggestion) => (
                <button
                  key={suggestion}
                  type="button"
                  onClick={() => setFeatures(suggestion)}
                  className="px-3 py-1 text-sm bg-purple-50 text-purple-700 rounded-full hover:bg-purple-100 transition-colors"
                >
                  {suggestion}
                </button>
              ))}
            </div>
          </div>
        </div>

        <button
          type="submit"
          className="w-full bg-purple-600 hover:bg-purple-700 text-white font-semibold py-3 px-6 rounded-lg transition duration-200 flex items-center justify-center gap-2"
        >
          <Building2 className="w-5 h-5" />
          Generate Cityscape
        </button>
      </form>
    </>
  );
}