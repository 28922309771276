import React, { useState } from 'react';
import { Landmark } from 'lucide-react';
import { LoadingOverlay } from './LoadingOverlay';
import toast from 'react-hot-toast';
import { useImageGeneration } from '../hooks/useImageGeneration';

interface ArchitecturalDetailsFormProps {
  onImageGenerated: (imageUrl: string) => void;
  setIsLoading: (loading: boolean) => void;
}

const ARCHITECTURAL_STYLES = [
  'Gothic',
  'Victorian',
  'Art Deco',
  'Modern'
] as const;

const ELEMENT_TYPES = [
  'Facade Details',
  'Ornamental Windows',
  'Decorative Columns',
  'Archways & Doors'
] as const;

const DETAIL_SUGGESTIONS = [
  'intricate stone carvings and gargoyles',
  'ornate window frames with floral patterns',
  'detailed column capitals and bases',
  'decorative arches with geometric designs',
  'elaborate doorways with carved details'
] as const;

export function ArchitecturalDetailsForm({ onImageGenerated, setIsLoading }: ArchitecturalDetailsFormProps) {
  const [style, setStyle] = useState<string>(ARCHITECTURAL_STYLES[0]);
  const [elementType, setElementType] = useState<string>(ELEMENT_TYPES[0]);
  const [details, setDetails] = useState('');
  const [isGenerating, setIsGenerating] = useState(false);
  const { generateWithCredits } = useImageGeneration();

  const handleGenerateImage = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!details.trim()) {
      toast.error('Please describe the architectural details');
      return;
    }

    setIsGenerating(true);
    setIsLoading(true);
    try {
      const prompt = `Create a detailed black and white line art illustration of ${style} style ${elementType.toLowerCase()} featuring ${details}. The design should be highly detailed and suitable for adult coloring books, including:
      - Intricate architectural details and ornamentations
      - Clear, bold outlines for easy coloring
      - Complex patterns and textures
      - Multiple layers of detail
      - Clean white background
      Make it sophisticated and challenging, perfect for advanced coloring enthusiasts who enjoy architectural details.`;
      
      const imageUrl = await generateWithCredits(prompt);
      if (imageUrl) {
        onImageGenerated(imageUrl);
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('Failed to generate image');
    } finally {
      setIsGenerating(false);
      setIsLoading(false);
    }
  };

  return (
    <>
      {isGenerating && <LoadingOverlay />}
      <form onSubmit={handleGenerateImage} className="space-y-6">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Architectural Style
          </label>
          <div className="grid grid-cols-2 gap-2">
            {ARCHITECTURAL_STYLES.map((option) => (
              <button
                key={option}
                type="button"
                onClick={() => setStyle(option)}
                className={`px-4 py-2 rounded-lg text-sm font-medium transition-colors ${
                  style === option
                    ? 'bg-purple-100 text-purple-700 border-2 border-purple-500'
                    : 'bg-gray-50 text-gray-700 border border-gray-300 hover:bg-gray-100'
                }`}
              >
                {option}
              </button>
            ))}
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Element Type
          </label>
          <div className="grid grid-cols-2 gap-2">
            {ELEMENT_TYPES.map((option) => (
              <button
                key={option}
                type="button"
                onClick={() => setElementType(option)}
                className={`px-4 py-2 rounded-lg text-sm font-medium transition-colors ${
                  elementType === option
                    ? 'bg-purple-100 text-purple-700 border-2 border-purple-500'
                    : 'bg-gray-50 text-gray-700 border border-gray-300 hover:bg-gray-100'
                }`}
              >
                {option}
              </button>
            ))}
          </div>
        </div>

        <div>
          <label htmlFor="details" className="block text-sm font-medium text-gray-700 mb-1">
            Architectural Details
          </label>
          <textarea
            id="details"
            value={details}
            onChange={(e) => setDetails(e.target.value)}
            placeholder="Describe the architectural details you'd like to see..."
            className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-purple-500 focus:border-purple-500 min-h-[100px] resize-y"
            required
          />
          <div className="mt-2">
            <p className="text-sm text-gray-600 mb-2">Suggestions:</p>
            <div className="flex flex-wrap gap-2">
              {DETAIL_SUGGESTIONS.map((suggestion) => (
                <button
                  key={suggestion}
                  type="button"
                  onClick={() => setDetails(suggestion)}
                  className="px-3 py-1 text-sm bg-purple-50 text-purple-700 rounded-full hover:bg-purple-100 transition-colors"
                >
                  {suggestion}
                </button>
              ))}
            </div>
          </div>
        </div>

        <button
          type="submit"
          className="w-full bg-purple-600 hover:bg-purple-700 text-white font-semibold py-3 px-6 rounded-lg transition duration-200 flex items-center justify-center gap-2"
        >
          <Landmark className="w-5 h-5" />
          Generate Architectural Details
        </button>
      </form>
    </>
  );
}