import React, { useState } from 'react';
import { Ghost } from 'lucide-react';
import { PageLayout } from '../../components/layout/PageLayout';
import { CoverMonsterFunForm } from '../../components/CoverMonsterFunForm';
import { ImagePreview } from '../../components/ImagePreview';

function CoverMonsterFun() {
  const [imageUrl, setImageUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  return (
    <PageLayout
      title="Cover - Monster Fun"
      description="Create colorful and playful monster designs for book covers!"
      icon={<Ghost className="w-10 h-10 text-purple-500" />}
      showBackButton={false}
    >
      <div className="p-6 space-y-8">
        <CoverMonsterFunForm 
          onImageGenerated={setImageUrl}
          setIsLoading={setIsLoading}
        />
        <ImagePreview imageUrl={imageUrl} isLoading={isLoading} />
      </div>
    </PageLayout>
  );
}

export default CoverMonsterFun;