import React, { useState } from 'react';
import { Ghost } from 'lucide-react';
import { LoadingOverlay } from './LoadingOverlay';
import toast from 'react-hot-toast';
import { useImageGeneration } from '../hooks/useImageGeneration';

interface CoverMonsterFunFormProps {
  onImageGenerated: (imageUrl: string) => void;
  setIsLoading: (loading: boolean) => void;
}

const COLOR_THEMES = [
  'Candy',
  'Rainbow',
  'Neon',
  'Pastel',
  'Primary',
  'Fantasy'
] as const;

const MONSTER_MOODS = [
  'Playful',
  'Silly',
  'Friendly',
  'Goofy',
  'Cheerful',
  'Mischievous'
] as const;

export function CoverMonsterFunForm({ onImageGenerated, setIsLoading }: CoverMonsterFunFormProps) {
  const [monsterType, setMonsterType] = useState('');
  const [activity, setActivity] = useState('');
  const [colorTheme, setColorTheme] = useState<string>(COLOR_THEMES[0]);
  const [mood, setMood] = useState<string>(MONSTER_MOODS[0]);
  const [isGenerating, setIsGenerating] = useState(false);
  const { generateWithCredits } = useImageGeneration();

  const handleGenerateImage = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!monsterType.trim() || !activity.trim()) {
      toast.error('Please fill in all fields');
      return;
    }

    setIsGenerating(true);
    setIsLoading(true);
    try {
      const prompt = `Create a colorful ${colorTheme.toLowerCase()} themed illustration of a ${mood.toLowerCase()} ${monsterType} monster ${activity}. The monster should be cute and child-friendly with expressive features. Use vibrant colors and a clean, cartoon style perfect for a children's book cover. The design should be cheerful and engaging with a simple background that complements the monster. Make it colorful and appealing to young children.`;
      
      const imageUrl = await generateWithCredits(prompt);
      if (imageUrl) {
        onImageGenerated(imageUrl);
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('Failed to generate image');
    } finally {
      setIsGenerating(false);
      setIsLoading(false);
    }
  };

  return (
    <>
      {isGenerating && <LoadingOverlay />}
      <form onSubmit={handleGenerateImage} className="space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <label htmlFor="monsterType" className="block text-sm font-medium text-gray-700 mb-1">
              Type of Monster
            </label>
            <input
              id="monsterType"
              type="text"
              value={monsterType}
              onChange={(e) => setMonsterType(e.target.value)}
              placeholder="e.g., fluffy, tentacled, spotted"
              className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-purple-500 focus:border-purple-500"
              required
            />
          </div>
          <div>
            <label htmlFor="activity" className="block text-sm font-medium text-gray-700 mb-1">
              What is the monster doing?
            </label>
            <input
              id="activity"
              type="text"
              value={activity}
              onChange={(e) => setActivity(e.target.value)}
              placeholder="e.g., playing with bubbles, juggling stars"
              className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-purple-500 focus:border-purple-500"
              required
            />
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Color Theme
          </label>
          <div className="grid grid-cols-2 sm:grid-cols-3 gap-2">
            {COLOR_THEMES.map((theme) => (
              <button
                key={theme}
                type="button"
                onClick={() => setColorTheme(theme)}
                className={`px-4 py-2 rounded-lg text-sm font-medium transition-colors ${
                  colorTheme === theme
                    ? 'bg-purple-100 text-purple-700 border-2 border-purple-500'
                    : 'bg-gray-50 text-gray-700 border border-gray-300 hover:bg-gray-100'
                }`}
              >
                {theme}
              </button>
            ))}
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Monster's Mood
          </label>
          <div className="grid grid-cols-2 sm:grid-cols-3 gap-2">
            {MONSTER_MOODS.map((option) => (
              <button
                key={option}
                type="button"
                onClick={() => setMood(option)}
                className={`px-4 py-2 rounded-lg text-sm font-medium transition-colors ${
                  mood === option
                    ? 'bg-purple-100 text-purple-700 border-2 border-purple-500'
                    : 'bg-gray-50 text-gray-700 border border-gray-300 hover:bg-gray-100'
                }`}
              >
                {option}
              </button>
            ))}
          </div>
        </div>

        <button
          type="submit"
          className="w-full bg-purple-600 hover:bg-purple-700 text-white font-semibold py-3 px-6 rounded-lg transition duration-200 flex items-center justify-center gap-2"
        >
          <Ghost className="w-5 h-5" />
          Generate Monster Cover
        </button>
      </form>
    </>
  );
}