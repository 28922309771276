import React, { useState } from 'react';
import { Smile } from 'lucide-react';
import { LoadingOverlay } from './LoadingOverlay';
import toast from 'react-hot-toast';
import { useImageGeneration } from '../hooks/useImageGeneration';

interface EmojiStyleFormProps {
  onImageGenerated: (imageUrl: string) => void;
  setIsLoading: (loading: boolean) => void;
}

const STYLE_OPTIONS = [
  'Kawaii',
  'Classic',
  'Modern',
  'Chibi',
  'Minimal',
  'Playful'
] as const;

const EXPRESSION_OPTIONS = [
  'Happy',
  'Excited',
  'Silly',
  'Cute',
  'Friendly',
  'Cheerful'
] as const;

export function EmojiStyleForm({ onImageGenerated, setIsLoading }: EmojiStyleFormProps) {
  const [subject, setSubject] = useState('');
  const [style, setStyle] = useState<string>(STYLE_OPTIONS[0]);
  const [expression, setExpression] = useState<string>(EXPRESSION_OPTIONS[0]);
  const [isGenerating, setIsGenerating] = useState(false);
  const { generateWithCredits } = useImageGeneration();

  const handleGenerateImage = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!subject.trim()) {
      toast.error('Please enter a subject');
      return;
    }

    setIsGenerating(true);
    setIsLoading(true);
    try {
      const prompt = `Create a black and white line art coloring page of a ${expression.toLowerCase()} ${subject} in ${style.toLowerCase()} emoji style. The design should be simple and clean with bold outlines, perfect for coloring. Include cute facial features and expressions typical of emoji characters. The background should be pure white, and the illustration should focus on the character with minimal decorative elements. Make it adorable and expressive like a modern emoji, but with clear lines for a coloring book.`;
      
      const imageUrl = await generateWithCredits(prompt);
      if (imageUrl) {
        onImageGenerated(imageUrl);
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('Failed to generate image');
    } finally {
      setIsGenerating(false);
      setIsLoading(false);
    }
  };

  return (
    <>
      {isGenerating && <LoadingOverlay />}
      <form onSubmit={handleGenerateImage} className="space-y-6">
        <div>
          <label htmlFor="subject" className="block text-sm font-medium text-gray-700 mb-1">
            What would you like to turn into an emoji?
          </label>
          <input
            id="subject"
            type="text"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            placeholder="e.g., cat, pizza, unicorn, robot"
            className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-yellow-500 focus:border-yellow-500"
            required
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Emoji Style
          </label>
          <div className="grid grid-cols-2 sm:grid-cols-3 gap-2">
            {STYLE_OPTIONS.map((option) => (
              <button
                key={option}
                type="button"
                onClick={() => setStyle(option)}
                className={`px-4 py-2 rounded-lg text-sm font-medium transition-colors ${
                  style === option
                    ? 'bg-yellow-100 text-yellow-700 border-2 border-yellow-500'
                    : 'bg-gray-50 text-gray-700 border border-gray-300 hover:bg-gray-100'
                }`}
              >
                {option}
              </button>
            ))}
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Expression
          </label>
          <div className="grid grid-cols-2 sm:grid-cols-3 gap-2">
            {EXPRESSION_OPTIONS.map((option) => (
              <button
                key={option}
                type="button"
                onClick={() => setExpression(option)}
                className={`px-4 py-2 rounded-lg text-sm font-medium transition-colors ${
                  expression === option
                    ? 'bg-yellow-100 text-yellow-700 border-2 border-yellow-500'
                    : 'bg-gray-50 text-gray-700 border border-gray-300 hover:bg-gray-100'
                }`}
              >
                {option}
              </button>
            ))}
          </div>
        </div>

        <button
          type="submit"
          className="w-full bg-yellow-500 hover:bg-yellow-600 text-white font-semibold py-3 px-6 rounded-lg transition duration-200 flex items-center justify-center gap-2"
        >
          <Smile className="w-5 h-5" />
          Generate Emoji Design
        </button>
      </form>
    </>
  );
}