import React, { useState } from 'react';
import { Sparkles } from 'lucide-react';
import { PageLayout } from '../../components/layout/PageLayout';
import { CoverTarotCardsForm } from '../../components/CoverTarotCardsForm';
import { ImagePreview } from '../../components/ImagePreview';

function CoverTarotCards() {
  const [imageUrl, setImageUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  return (
    <PageLayout
      title="Cover - Tarot Cards"
      description="Create colorful and mystical tarot card designs for book covers!"
      icon={<Sparkles className="w-10 h-10 text-violet-500" />}
      showBackButton={false}
    >
      <div className="p-6 space-y-8">
        <CoverTarotCardsForm 
          onImageGenerated={setImageUrl}
          setIsLoading={setIsLoading}
        />
        <ImagePreview imageUrl={imageUrl} isLoading={isLoading} />
      </div>
    </PageLayout>
  );
}

export default CoverTarotCards;