import React, { useState } from 'react';
import { Type, Sparkles } from 'lucide-react';
import { LoadingOverlay } from './LoadingOverlay';
import toast from 'react-hot-toast';
import { useImageGeneration } from '../hooks/useImageGeneration';

interface BubbleTextFormProps {
  onImageGenerated: (imageUrl: string) => void;
  setIsLoading: (loading: boolean) => void;
}

const STYLE_OPTIONS = [
  'Bubble',
  'Graffiti',
  'Comic',
  'Chunky',
  'Puffy',
  'Block'
] as const;

const DECORATION_OPTIONS = [
  'stars and sparkles',
  'hearts and swirls',
  'dots and dashes',
  'geometric shapes',
  'lightning bolts',
  'confetti'
] as const;

export function BubbleTextForm({ onImageGenerated, setIsLoading }: BubbleTextFormProps) {
  const [text, setText] = useState('');
  const [style, setStyle] = useState<string>(STYLE_OPTIONS[0]);
  const [decorations, setDecorations] = useState<string>(DECORATION_OPTIONS[0]);
  const [isGenerating, setIsGenerating] = useState(false);
  const { generateWithCredits } = useImageGeneration();

  const handleGenerateImage = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!text.trim()) {
      toast.error('Please enter some text');
      return;
    }

    setIsGenerating(true);
    setIsLoading(true);
    try {
      const prompt = `Create a black and white outline coloring page with the text "${text}" in ${style.toLowerCase()} letter style. The letters should be thick, rounded, and bubble-like with clean outlines perfect for coloring. Add ${decorations} around the text. Make it in the style of a coloring book with bold black outlines on white background, similar to graffiti or comic book lettering. Each letter should have highlights and dimension marks to create a 3D effect when colored. The design should be fun and playful, perfect for a coloring book page.`;
      
      const imageUrl = await generateWithCredits(prompt);
      if (imageUrl) {
        onImageGenerated(imageUrl);
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('Failed to generate image');
    } finally {
      setIsGenerating(false);
      setIsLoading(false);
    }
  };

  return (
    <>
      {isGenerating && <LoadingOverlay />}
      <form onSubmit={handleGenerateImage} className="space-y-6">
        <div>
          <label htmlFor="text" className="block text-sm font-medium text-gray-700 mb-1">
            Enter Your Text
          </label>
          <textarea
            id="text"
            value={text}
            onChange={(e) => setText(e.target.value)}
            placeholder="e.g., Dream Big, Stay Kind, Be Happy"
            className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 min-h-[100px] resize-y"
            required
          />
          <p className="mt-1 text-sm text-gray-500">
            Keep it short for the best results (1-4 words recommended)
          </p>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Letter Style
          </label>
          <div className="grid grid-cols-2 sm:grid-cols-3 gap-2">
            {STYLE_OPTIONS.map((option) => (
              <button
                key={option}
                type="button"
                onClick={() => setStyle(option)}
                className={`px-4 py-2 rounded-lg text-sm font-medium transition-colors ${
                  style === option
                    ? 'bg-blue-100 text-blue-700 border-2 border-blue-500'
                    : 'bg-gray-50 text-gray-700 border border-gray-300 hover:bg-gray-100'
                }`}
              >
                {option}
              </button>
            ))}
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Decorative Elements
          </label>
          <div className="grid grid-cols-2 sm:grid-cols-3 gap-2">
            {DECORATION_OPTIONS.map((option) => (
              <button
                key={option}
                type="button"
                onClick={() => setDecorations(option)}
                className={`px-4 py-2 rounded-lg text-sm font-medium transition-colors ${
                  decorations === option
                    ? 'bg-blue-100 text-blue-700 border-2 border-blue-500'
                    : 'bg-gray-50 text-gray-700 border border-gray-300 hover:bg-gray-100'
                }`}
              >
                {option.split(' and ')[0]}
              </button>
            ))}
          </div>
        </div>

        <button
          type="submit"
          className="w-full bg-blue-600 hover:bg-blue-700 text-white font-semibold py-3 px-6 rounded-lg transition duration-200 flex items-center justify-center gap-2"
        >
          <Type className="w-5 h-5" />
          Generate Bubble Text
        </button>
      </form>
    </>
  );
}