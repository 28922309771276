import React, { useState } from 'react';
import { Palette } from 'lucide-react';
import { PageLayout } from '../../components/layout/PageLayout';
import { CoverSimpleEasyForm } from '../../components/CoverSimpleEasyForm';
import { ImagePreview } from '../../components/ImagePreview';

function CoverSimpleEasy() {
  const [imageUrl, setImageUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  return (
    <PageLayout
      title="Cover - Simple & Easy"
      description="Create colorful cover designs with simple, child-friendly illustrations!"
      icon={<Palette className="w-10 h-10 text-blue-500" />}
      showBackButton={false}
    >
      <div className="p-6 space-y-8">
        <CoverSimpleEasyForm 
          onImageGenerated={setImageUrl}
          setIsLoading={setIsLoading}
        />
        <ImagePreview imageUrl={imageUrl} isLoading={isLoading} />
      </div>
    </PageLayout>
  );
}

export default CoverSimpleEasy;