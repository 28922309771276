import React, { useState } from 'react';
import { Quote } from 'lucide-react';
import { LoadingOverlay } from './LoadingOverlay';
import toast from 'react-hot-toast';
import { useImageGeneration } from '../hooks/useImageGeneration';

interface CoverQuotesDoodleFormProps {
  onImageGenerated: (imageUrl: string) => void;
  setIsLoading: (loading: boolean) => void;
}

const COLOR_THEMES = [
  'Vibrant',
  'Pastel',
  'Rainbow',
  'Sunset',
  'Ocean',
  'Garden'
] as const;

const STYLE_OPTIONS = [
  'Whimsical',
  'Modern',
  'Elegant',
  'Playful',
  'Bohemian',
  'Minimalist'
] as const;

const DOODLE_ELEMENTS = [
  'flowers and leaves',
  'stars and moons',
  'hearts and swirls',
  'birds and butterflies',
  'geometric shapes',
  'clouds and rainbows'
] as const;

export function CoverQuotesDoodleForm({ onImageGenerated, setIsLoading }: CoverQuotesDoodleFormProps) {
  const [quote, setQuote] = useState('');
  const [elements, setElements] = useState('');
  const [colorTheme, setColorTheme] = useState<string>(COLOR_THEMES[0]);
  const [style, setStyle] = useState<string>(STYLE_OPTIONS[0]);
  const [isGenerating, setIsGenerating] = useState(false);
  const { generateWithCredits } = useImageGeneration();

  const handleGenerateImage = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!quote.trim() || !elements.trim()) {
      toast.error('Please fill in all fields');
      return;
    }

    setIsGenerating(true);
    setIsLoading(true);
    try {
      const prompt = `Create a colorful ${colorTheme.toLowerCase()} themed ${style.toLowerCase()} typography design featuring the quote "${quote}" decorated with ${elements}. The text should be beautifully arranged with decorative doodle elements flowing around it. Use vibrant colors and a clean style perfect for a book cover. The design should be inspiring and visually appealing with a harmonious composition. Make it colorful and eye-catching with elegant typography and playful doodles.`;
      
      const imageUrl = await generateWithCredits(prompt);
      if (imageUrl) {
        onImageGenerated(imageUrl);
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('Failed to generate image');
    } finally {
      setIsGenerating(false);
      setIsLoading(false);
    }
  };

  return (
    <>
      {isGenerating && <LoadingOverlay />}
      <form onSubmit={handleGenerateImage} className="space-y-6">
        <div>
          <label htmlFor="quote" className="block text-sm font-medium text-gray-700 mb-1">
            Your Quote
          </label>
          <textarea
            id="quote"
            value={quote}
            onChange={(e) => setQuote(e.target.value)}
            placeholder="e.g., Dream big, shine bright"
            className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-purple-500 focus:border-purple-500 min-h-[100px] resize-y"
            required
          />
        </div>

        <div>
          <label htmlFor="elements" className="block text-sm font-medium text-gray-700 mb-1">
            Decorative Elements
          </label>
          <input
            id="elements"
            type="text"
            value={elements}
            onChange={(e) => setElements(e.target.value)}
            placeholder="e.g., flowers and leaves, stars and moons"
            className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-purple-500 focus:border-purple-500"
            required
          />
          <div className="mt-2 flex flex-wrap gap-2">
            {DOODLE_ELEMENTS.map((suggestion) => (
              <button
                key={suggestion}
                type="button"
                onClick={() => setElements(suggestion)}
                className="px-3 py-1 text-sm bg-purple-50 text-purple-700 rounded-full hover:bg-purple-100 transition-colors"
              >
                {suggestion}
              </button>
            ))}
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Color Theme
          </label>
          <div className="grid grid-cols-2 sm:grid-cols-3 gap-2">
            {COLOR_THEMES.map((theme) => (
              <button
                key={theme}
                type="button"
                onClick={() => setColorTheme(theme)}
                className={`px-4 py-2 rounded-lg text-sm font-medium transition-colors ${
                  colorTheme === theme
                    ? 'bg-purple-100 text-purple-700 border-2 border-purple-500'
                    : 'bg-gray-50 text-gray-700 border border-gray-300 hover:bg-gray-100'
                }`}
              >
                {theme}
              </button>
            ))}
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Design Style
          </label>
          <div className="grid grid-cols-2 sm:grid-cols-3 gap-2">
            {STYLE_OPTIONS.map((option) => (
              <button
                key={option}
                type="button"
                onClick={() => setStyle(option)}
                className={`px-4 py-2 rounded-lg text-sm font-medium transition-colors ${
                  style === option
                    ? 'bg-purple-100 text-purple-700 border-2 border-purple-500'
                    : 'bg-gray-50 text-gray-700 border border-gray-300 hover:bg-gray-100'
                }`}
              >
                {option}
              </button>
            ))}
          </div>
        </div>

        <button
          type="submit"
          className="w-full bg-purple-600 hover:bg-purple-700 text-white font-semibold py-3 px-6 rounded-lg transition duration-200 flex items-center justify-center gap-2"
        >
          <Quote className="w-5 h-5" />
          Generate Quote Cover
        </button>
      </form>
    </>
  );
}