import React, { useState } from 'react';
import { Cookie } from 'lucide-react';
import { PageLayout } from '../../components/layout/PageLayout';
import { CoverCuteFoodForm } from '../../components/CoverCuteFoodForm';
import { ImagePreview } from '../../components/ImagePreview';

function CoverCuteFood() {
  const [imageUrl, setImageUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  return (
    <PageLayout
      title="Cover - Cute Food"
      description="Create colorful and adorable food character designs for book covers!"
      icon={<Cookie className="w-10 h-10 text-emerald-500" />}
      showBackButton={false}
    >
      <div className="p-6 space-y-8">
        <CoverCuteFoodForm 
          onImageGenerated={setImageUrl}
          setIsLoading={setIsLoading}
        />
        <ImagePreview imageUrl={imageUrl} isLoading={isLoading} />
      </div>
    </PageLayout>
  );
}

export default CoverCuteFood;