import React, { useState } from 'react';
import { Text } from 'lucide-react';
import { LoadingOverlay } from './LoadingOverlay';
import toast from 'react-hot-toast';
import { useImageGeneration } from '../hooks/useImageGeneration';

interface DoodleLettersFormProps {
  onImageGenerated: (imageUrl: string) => void;
  setIsLoading: (loading: boolean) => void;
}

export function DoodleLettersForm({ onImageGenerated, setIsLoading }: DoodleLettersFormProps) {
  const [letter, setLetter] = useState('');
  const [isGenerating, setIsGenerating] = useState(false);
  const { generateWithCredits } = useImageGeneration();

  const handleGenerateImage = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!letter.trim()) {
      toast.error('Please enter a letter or number');
      return;
    }

    const char = letter.charAt(0).toUpperCase();
    if (!/^[A-Z0-9]$/.test(char)) {
      toast.error('Please enter a valid letter or number');
      return;
    }

    setIsGenerating(true);
    setIsLoading(true);
    try {
      const prompt = `Create a black and white line art illustration of the letter "${char}" filled with cute kawaii-style monster doodles. The design should include:
      - A large, bold letter "${char}" that forms the boundary
      - Fill the entire letter shape with various cute monster faces and doodle creatures
      - Monsters should have different expressions (happy, silly, surprised)
      - Include big round eyes, simple smiles, and kawaii-style features
      - Mix different sizes of monsters to create visual interest
      - Add small decorative elements like dots, swirls, and patterns between monsters
      - Clean, bold outlines perfect for coloring
      - Pure white background with no elements outside the letter
      - Style similar to kawaii doodle art
      The monsters should be tightly packed together forming a cohesive pattern within the letter shape, similar to a monster doodle collage. Make it perfect for a coloring book page.`;
      
      const imageUrl = await generateWithCredits(prompt);
      if (imageUrl) {
        onImageGenerated(imageUrl);
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('Failed to generate image');
    } finally {
      setIsGenerating(false);
      setIsLoading(false);
    }
  };

  return (
    <>
      {isGenerating && <LoadingOverlay />}
      <form onSubmit={handleGenerateImage} className="space-y-6">
        <div>
          <label htmlFor="letter" className="block text-sm font-medium text-gray-700 mb-1">
            Enter a Letter or Number
          </label>
          <input
            id="letter"
            type="text"
            value={letter}
            onChange={(e) => setLetter(e.target.value.slice(0, 1))}
            placeholder="e.g., A, B, C, 1, 2, 3"
            className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            required
            maxLength={1}
          />
          <p className="mt-1 text-sm text-gray-500">
            The letter or number will be filled with cute monster doodles
          </p>
        </div>

        <button
          type="submit"
          className="w-full bg-blue-600 hover:bg-blue-700 text-white font-semibold py-3 px-6 rounded-lg transition duration-200 flex items-center justify-center gap-2"
        >
          <Text className="w-5 h-5" />
          Generate Doodle Letter
        </button>
      </form>
    </>
  );
}