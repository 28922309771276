import React, { useState } from 'react';
import { Text } from 'lucide-react';
import { LoadingOverlay } from './LoadingOverlay';
import toast from 'react-hot-toast';
import { useImageGeneration } from '../hooks/useImageGeneration';

interface AlphabetFormProps {
  onImageGenerated: (imageUrl: string) => void;
  setIsLoading: (loading: boolean) => void;
}

export function AlphabetForm({ onImageGenerated, setIsLoading }: AlphabetFormProps) {
  const [subject, setSubject] = useState('');
  const [isGenerating, setIsGenerating] = useState(false);
  const { generateWithCredits } = useImageGeneration();

  const handleGenerateImage = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!subject.trim()) {
      toast.error('Please enter a subject');
      return;
    }

    setIsGenerating(true);
    setIsLoading(true);
    try {
      const firstLetter = subject.charAt(0).toUpperCase();
      const prompt = `Create a black and white line art illustration for a children's alphabet coloring book featuring the letter "${firstLetter}" and a cute ${subject}. The design should include:
      - A large, clear letter "${firstLetter}" with simple outlines
      - A cute, cartoon-style ${subject} interacting with or positioned next to the letter
      - Clean, bold black lines on a pure white background
      - NO shading, textures, or gradients
      - NO background elements or decorations
      - Simple, child-friendly style perfect for coloring
      - The letter and ${subject} should be well-integrated in the composition
      Make sure the image has clear spaces for coloring and is centered on a completely white background.`;
      
      const imageUrl = await generateWithCredits(prompt);
      if (imageUrl) {
        onImageGenerated(imageUrl);
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('Failed to generate image');
    } finally {
      setIsGenerating(false);
      setIsLoading(false);
    }
  };

  return (
    <>
      {isGenerating && <LoadingOverlay />}
      <form onSubmit={handleGenerateImage} className="space-y-6">
        <div>
          <label htmlFor="subject" className="block text-sm font-medium text-gray-700 mb-1">
            What would you like to draw?
          </label>
          <input
            id="subject"
            type="text"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            placeholder="e.g., bear, dolphin, elephant"
            className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            required
          />
          <p className="mt-1 text-sm text-gray-500">
            The first letter of your subject will be used to create the alphabet design
          </p>
        </div>

        <button
          type="submit"
          className="w-full bg-blue-600 hover:bg-blue-700 text-white font-semibold py-3 px-6 rounded-lg transition duration-200 flex items-center justify-center gap-2"
        >
          <Text className="w-5 h-5" />
          Generate Alphabet Design
        </button>
      </form>
    </>
  );
}