import React from 'react';
import { Outlet, Routes, Route } from 'react-router-dom';
import { CategoryLayout } from '../../components/layout/CategoryLayout';
import { Brain, Flower2, Sparkles, Globe2, UserCircle2, Users, Palette, Building2, Landmark } from 'lucide-react';
import MandalaArt from './MandalaArt';
import CoverTarotCards from './CoverTarotCards';
import CoverMandalaArt from './CoverMandalaArt';
import CoverHoliday from './CoverHoliday';
import TarotCards from './TarotCards';
import MiniatureWorld from './MiniatureWorld';
import MysteryCharacters from './MysteryCharacters';
import QuarrelScene from './QuarrelScene';
import DetailedCityscape from './DetailedCityscape';
import ArchitecturalDetails from './ArchitecturalDetails';

const tools = [
  {
    id: 'cover-holiday',
    title: 'Cover - Holiday Theme',
    description: 'Create colorful holiday-themed covers',
    icon: Palette,
    path: 'cover-holiday'
  },
  {
    id: 'cover-mandala',
    title: 'Cover - Mandala Art',
    description: 'Create colorful mandala designs',
    icon: Flower2,
    path: 'cover-mandala'
  },
  {
    id: 'cover-tarot',
    title: 'Cover - Tarot Cards',
    description: 'Create colorful mystical card designs',
    icon: Sparkles,
    path: 'cover-tarot'
  },
  {
    id: 'mandala',
    title: 'Mandala Art',
    description: 'Create intricate mandala designs',
    icon: Flower2,
    path: 'mandala'
  },
  {
    id: 'tarot-cards',
    title: 'Tarot Cards',
    description: 'Design mystical tarot card illustrations',
    icon: Sparkles,
    path: 'tarot-cards'
  },
  {
    id: 'miniature-world',
    title: 'Miniature World',
    description: 'Create enchanting miniature scenes',
    icon: Globe2,
    path: 'miniature-world'
  },
  {
    id: 'mystery-characters',
    title: 'Mystery Characters',
    description: 'Design enigmatic character scenes',
    icon: UserCircle2,
    path: 'mystery-characters'
  },
  {
    id: 'quarrel-scene',
    title: 'Quarrel Scene',
    description: 'Create amusing disagreement scenes',
    icon: Users,
    path: 'quarrel-scene'
  },
  {
    id: 'detailed-cityscape',
    title: 'Detailed Cityscape',
    description: 'Create intricate city scenes',
    icon: Building2,
    path: 'detailed-cityscape'
  },
  {
    id: 'architectural-details',
    title: 'Architectural Details',
    description: 'Design detailed architectural elements',
    icon: Landmark,
    path: 'architectural-details'
  }
];

function AdultLayout() {
  return (
    <CategoryLayout
      title="Adult Coloring"
      description="Complex and detailed designs for adults"
      icon={Brain}
      color="purple"
      tools={tools}
    >
      <Routes>
        <Route path="cover-holiday" element={<CoverHoliday />} />
        <Route path="cover-mandala" element={<CoverMandalaArt />} />
        <Route path="cover-tarot" element={<CoverTarotCards />} />
        <Route path="mandala" element={<MandalaArt />} />
        <Route path="tarot-cards" element={<TarotCards />} />
        <Route path="miniature-world" element={<MiniatureWorld />} />
        <Route path="mystery-characters" element={<MysteryCharacters />} />
        <Route path="quarrel-scene" element={<QuarrelScene />} />
        <Route path="detailed-cityscape" element={<DetailedCityscape />} />
        <Route path="architectural-details" element={<ArchitecturalDetails />} />
      </Routes>
    </CategoryLayout>
  );
}

export default AdultLayout;