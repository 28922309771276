import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { CategoryLayout } from '../../components/layout/CategoryLayout';
import { Lightbulb, Palette, BookOpen, Copyright, Type } from 'lucide-react';
import ColoringIdeas from './ColoringIdeas';
import AmazonDescription from './AmazonDescription';
import CopyrightPage from './CopyrightPage';
import TitleGenerator from '../TitleGenerator';

const tools = [
  {
    id: 'coloring-ideas',
    title: 'Coloring Ideas',
    description: 'Generate creative coloring page themes',
    icon: Palette,
    path: 'coloring-ideas'
  },
  {
    id: 'title-generator',
    title: 'Title Generator',
    description: 'Create beautiful book titles',
    icon: Type,
    path: 'title-generator'
  },
  {
    id: 'amazon-description',
    title: 'Amazon Description',
    description: 'Generate optimized book descriptions',
    icon: BookOpen,
    path: 'amazon-description'
  },
  {
    id: 'copyright-page',
    title: 'Copyright Page',
    description: 'Generate professional copyright pages',
    icon: Copyright,
    path: 'copyright-page'
  }
];

function ContentLayout() {
  return (
    <CategoryLayout
      title="Content Ideas"
      description="AI-powered coloring inspiration and planning"
      icon={Lightbulb}
      color="yellow"
      tools={tools}
    >
      <Routes>
        <Route path="coloring-ideas" element={<ColoringIdeas />} />
        <Route path="title-generator" element={<TitleGenerator />} />
        <Route path="amazon-description" element={<AmazonDescription />} />
        <Route path="copyright-page" element={<CopyrightPage />} />
      </Routes>
    </CategoryLayout>
  );
}

export default ContentLayout;