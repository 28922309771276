import React, { useState } from 'react';
import { Heart } from 'lucide-react';
import { LoadingOverlay } from './LoadingOverlay';
import toast from 'react-hot-toast';
import { useImageGeneration } from '../hooks/useImageGeneration';

interface CoverCozyFriendFormProps {
  onImageGenerated: (imageUrl: string) => void;
  setIsLoading: (loading: boolean) => void;
}

const THEME_OPTIONS = [
  'Pastel',
  'Kawaii',
  'Minimalist',
  'Cartoon',
  'Whimsical',
  'Dreamy'
] as const;

export function CoverCozyFriendForm({ onImageGenerated, setIsLoading }: CoverCozyFriendFormProps) {
  const [firstAnimal, setFirstAnimal] = useState('');
  const [secondAnimal, setSecondAnimal] = useState('');
  const [activity, setActivity] = useState('');
  const [place, setPlace] = useState('');
  const [theme, setTheme] = useState<string>(THEME_OPTIONS[0]);
  const [isGenerating, setIsGenerating] = useState(false);
  const { generateWithCredits } = useImageGeneration();

  const handleGenerateImage = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!firstAnimal.trim() || !secondAnimal.trim() || !activity.trim() || !place.trim()) {
      toast.error('Please fill in all fields');
      return;
    }

    setIsGenerating(true);
    setIsLoading(true);
    try {
      const prompt = `A ${firstAnimal} and ${secondAnimal} as best friends, happily and smiles in a cozy place. Simple, child-friendly outline art with ${activity} between characters. Warm and inviting scene with ${place} as background outlines. The style is simple and ideal for kids, with clear outlines on a soft tone background. Make it a minimalist ${theme} cartoon illustration for little children.`;
      
      const imageUrl = await generateWithCredits(prompt);
      if (imageUrl) {
        onImageGenerated(imageUrl);
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('Failed to generate image');
    } finally {
      setIsGenerating(false);
      setIsLoading(false);
    }
  };

  return (
    <>
      {isGenerating && <LoadingOverlay />}
      <form onSubmit={handleGenerateImage} className="space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <label htmlFor="firstAnimal" className="block text-sm font-medium text-gray-700 mb-1">
              First Animal
            </label>
            <input
              id="firstAnimal"
              type="text"
              value={firstAnimal}
              onChange={(e) => setFirstAnimal(e.target.value)}
              placeholder="e.g., Bunny, Penguin"
              className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-pink-500 focus:border-pink-500"
              required
            />
          </div>
          <div>
            <label htmlFor="secondAnimal" className="block text-sm font-medium text-gray-700 mb-1">
              Second Animal
            </label>
            <input
              id="secondAnimal"
              type="text"
              value={secondAnimal}
              onChange={(e) => setSecondAnimal(e.target.value)}
              placeholder="e.g., Bear, Fox"
              className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-pink-500 focus:border-pink-500"
              required
            />
          </div>
          <div>
            <label htmlFor="activity" className="block text-sm font-medium text-gray-700 mb-1">
              What are they doing?
            </label>
            <input
              id="activity"
              type="text"
              value={activity}
              onChange={(e) => setActivity(e.target.value)}
              placeholder="e.g., sharing cookies, reading books"
              className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-pink-500 focus:border-pink-500"
              required
            />
          </div>
          <div>
            <label htmlFor="place" className="block text-sm font-medium text-gray-700 mb-1">
              Background Scene
            </label>
            <input
              id="place"
              type="text"
              value={place}
              onChange={(e) => setPlace(e.target.value)}
              placeholder="e.g., cozy garden, treehouse"
              className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-pink-500 focus:border-pink-500"
              required
            />
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Choose Art Style
          </label>
          <div className="grid grid-cols-2 sm:grid-cols-3 gap-2">
            {THEME_OPTIONS.map((option) => (
              <button
                key={option}
                type="button"
                onClick={() => setTheme(option)}
                className={`px-4 py-2 rounded-lg text-sm font-medium transition-colors ${
                  theme === option
                    ? 'bg-pink-100 text-pink-700 border-2 border-pink-500'
                    : 'bg-gray-50 text-gray-700 border border-gray-300 hover:bg-gray-100'
                }`}
              >
                {option}
              </button>
            ))}
          </div>
        </div>

        <button
          type="submit"
          className="w-full bg-pink-600 hover:bg-pink-700 text-white font-semibold py-3 px-6 rounded-lg transition duration-200 flex items-center justify-center gap-2"
        >
          <Heart className="w-5 h-5" />
          Generate Cover Design
        </button>
      </form>
    </>
  );
}