import React from 'react';
import { Wand2 } from 'lucide-react';

export function LoadingOverlay() {
  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
      <div className="bg-white p-8 rounded-xl shadow-lg flex flex-col items-center gap-6">
        {/* Magic Circle Container */}
        <div className="relative w-24 h-24">
          {/* Outer Ring with Gradient */}
          <div className="absolute inset-0 rounded-full border-4 border-t-blue-500 border-r-blue-400 border-b-blue-300 border-l-blue-200 animate-[spin_3s_linear_infinite]" />
          
          {/* Inner Circle with Pulse */}
          <div className="absolute inset-2 rounded-full bg-gradient-to-tr from-blue-50 to-purple-50 animate-[pulse_2s_ease-in-out_infinite]" />
          
          {/* Central Wand */}
          <div className="absolute inset-0 flex items-center justify-center">
            <Wand2 className="w-8 h-8 text-blue-500 transform -rotate-45 animate-[bounce_2s_ease-in-out_infinite]" />
          </div>
          
          {/* Floating Sparkles */}
          <div className="absolute inset-0">
            {[...Array(4)].map((_, i) => (
              <div
                key={i}
                className="absolute animate-[ping_2s_ease-in-out_infinite]"
                style={{
                  top: `${Math.random() * 100}%`,
                  left: `${Math.random() * 100}%`,
                  animationDelay: `${i * 0.5}s`,
                }}
              >
                <div className="w-2 h-2 bg-blue-400 rounded-full" />
              </div>
            ))}
          </div>
        </div>
        
        {/* Loading Text */}
        <div className="text-center space-y-1">
          <p className="text-lg font-medium text-gray-700">Creating Magic...</p>
          <p className="text-sm text-gray-500">Your coloring page is being generated ✨</p>
        </div>
      </div>
    </div>
  );
}