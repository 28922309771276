import React from 'react';
import { Book, ExternalLink, Wand2 } from 'lucide-react';

export default function PublishingTools() {
  const tools = [
    {
      title: 'Word Activity Generator',
      description: 'Create engaging activity book content in less than 5 minutes! Generate word searches, crosswords, mazes, and more with AI assistance.',
      url: 'https://wordactivity.com',
      icon: Wand2,
      color: 'blue'
    },
    {
      title: 'KDP All-in-One Tools',
      description: 'Everything you need to publish on Amazon KDP - keyword research, category finder, description generator, and more tools to boost your publishing success.',
      url: 'https://kdp.im',
      icon: Book,
      color: 'green'
    }
  ];

  return (
    <div className="min-h-screen py-8 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl mx-auto space-y-8">
        <div className="text-center">
          <h1 className="text-3xl font-bold text-gray-900">Publishing Tools</h1>
          <p className="mt-2 text-gray-600">Essential tools to help you create and publish your books</p>
        </div>

        <div className="grid gap-6 md:grid-cols-2">
          {tools.map((tool) => {
            const Icon = tool.icon;
            return (
              <a
                key={tool.url}
                href={tool.url}
                target="_blank"
                rel="noopener noreferrer"
                className="group bg-white p-6 rounded-xl shadow-lg hover:shadow-xl transition-all duration-300"
              >
                <div className="flex items-start gap-4">
                  <div className={`p-3 bg-${tool.color}-50 rounded-lg group-hover:scale-110 transition-transform`}>
                    <Icon className={`w-6 h-6 text-${tool.color}-500`} />
                  </div>
                  <div className="flex-1">
                    <div className="flex items-center gap-2">
                      <h3 className="text-lg font-semibold text-gray-900">{tool.title}</h3>
                      <ExternalLink className="w-4 h-4 text-gray-400 group-hover:text-gray-600" />
                    </div>
                    <p className="mt-2 text-gray-600">{tool.description}</p>
                  </div>
                </div>
              </a>
            );
          })}
        </div>
      </div>
    </div>
  );
}