import React, { useState } from 'react';
import { PenTool } from 'lucide-react';
import { PageLayout } from '../../components/layout/PageLayout';
import { CoverDoodlePatternForm } from '../../components/CoverDoodlePatternForm';
import { ImagePreview } from '../../components/ImagePreview';

function CoverDoodlePattern() {
  const [imageUrl, setImageUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  return (
    <PageLayout
      title="Cover - Doodle Pattern"
      description="Create colorful and playful doodle patterns for book covers!"
      icon={<PenTool className="w-10 h-10 text-teal-500" />}
      showBackButton={false}
    >
      <div className="p-6 space-y-8">
        <CoverDoodlePatternForm 
          onImageGenerated={setImageUrl}
          setIsLoading={setIsLoading}
        />
        <ImagePreview imageUrl={imageUrl} isLoading={isLoading} />
      </div>
    </PageLayout>
  );
}

export default CoverDoodlePattern;