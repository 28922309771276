import React, { useState } from 'react';
import { Car } from 'lucide-react';
import { LoadingOverlay } from './LoadingOverlay';
import toast from 'react-hot-toast';
import { useImageGeneration } from '../hooks/useImageGeneration';

interface CoverHappyVehiclesFormProps {
  onImageGenerated: (imageUrl: string) => void;
  setIsLoading: (loading: boolean) => void;
}

const COLOR_THEMES = [
  'Candy',
  'Primary',
  'Pastel',
  'Rainbow',
  'Retro',
  'Neon'
] as const;

const VEHICLE_MOODS = [
  'Happy',
  'Playful',
  'Excited',
  'Friendly',
  'Cheerful',
  'Energetic'
] as const;

const BACKGROUND_SUGGESTIONS = [
  'city street',
  'race track',
  'countryside road',
  'construction site',
  'beach',
  'park'
] as const;

export function CoverHappyVehiclesForm({ onImageGenerated, setIsLoading }: CoverHappyVehiclesFormProps) {
  const [vehicleType, setVehicleType] = useState('');
  const [background, setBackground] = useState('');
  const [colorTheme, setColorTheme] = useState<string>(COLOR_THEMES[0]);
  const [mood, setMood] = useState<string>(VEHICLE_MOODS[0]);
  const [isGenerating, setIsGenerating] = useState(false);
  const { generateWithCredits } = useImageGeneration();

  const handleGenerateImage = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!vehicleType.trim() || !background.trim()) {
      toast.error('Please fill in all fields');
      return;
    }

    setIsGenerating(true);
    setIsLoading(true);
    try {
      const prompt = `Create a colorful ${colorTheme.toLowerCase()} themed illustration of a ${mood.toLowerCase()} ${vehicleType} with a cute face and expressive features, set in a cheerful ${background} scene. The vehicle should have big friendly eyes and a warm smile. Use vibrant colors and a clean, cartoon style perfect for a children's book cover. The design should be engaging and playful with simple details that complement the main character. Make it colorful and appealing to young children.`;
      
      const imageUrl = await generateWithCredits(prompt);
      if (imageUrl) {
        onImageGenerated(imageUrl);
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('Failed to generate image');
    } finally {
      setIsGenerating(false);
      setIsLoading(false);
    }
  };

  return (
    <>
      {isGenerating && <LoadingOverlay />}
      <form onSubmit={handleGenerateImage} className="space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <label htmlFor="vehicleType" className="block text-sm font-medium text-gray-700 mb-1">
              Type of Vehicle
            </label>
            <input
              id="vehicleType"
              type="text"
              value={vehicleType}
              onChange={(e) => setVehicleType(e.target.value)}
              placeholder="e.g., fire truck, race car, train"
              className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-cyan-500 focus:border-cyan-500"
              required
            />
          </div>
          <div>
            <label htmlFor="background" className="block text-sm font-medium text-gray-700 mb-1">
              Background Scene
            </label>
            <input
              id="background"
              type="text"
              value={background}
              onChange={(e) => setBackground(e.target.value)}
              placeholder="e.g., city street, race track"
              className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-cyan-500 focus:border-cyan-500"
              required
            />
            <div className="mt-2 flex flex-wrap gap-2">
              {BACKGROUND_SUGGESTIONS.map((suggestion) => (
                <button
                  key={suggestion}
                  type="button"
                  onClick={() => setBackground(suggestion)}
                  className="px-3 py-1 text-sm bg-cyan-50 text-cyan-700 rounded-full hover:bg-cyan-100 transition-colors"
                >
                  {suggestion}
                </button>
              ))}
            </div>
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Color Theme
          </label>
          <div className="grid grid-cols-2 sm:grid-cols-3 gap-2">
            {COLOR_THEMES.map((theme) => (
              <button
                key={theme}
                type="button"
                onClick={() => setColorTheme(theme)}
                className={`px-4 py-2 rounded-lg text-sm font-medium transition-colors ${
                  colorTheme === theme
                    ? 'bg-cyan-100 text-cyan-700 border-2 border-cyan-500'
                    : 'bg-gray-50 text-gray-700 border border-gray-300 hover:bg-gray-100'
                }`}
              >
                {theme}
              </button>
            ))}
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Vehicle's Mood
          </label>
          <div className="grid grid-cols-2 sm:grid-cols-3 gap-2">
            {VEHICLE_MOODS.map((option) => (
              <button
                key={option}
                type="button"
                onClick={() => setMood(option)}
                className={`px-4 py-2 rounded-lg text-sm font-medium transition-colors ${
                  mood === option
                    ? 'bg-cyan-100 text-cyan-700 border-2 border-cyan-500'
                    : 'bg-gray-50 text-gray-700 border border-gray-300 hover:bg-gray-100'
                }`}
              >
                {option}
              </button>
            ))}
          </div>
        </div>

        <button
          type="submit"
          className="w-full bg-cyan-600 hover:bg-cyan-700 text-white font-semibold py-3 px-6 rounded-lg transition duration-200 flex items-center justify-center gap-2"
        >
          <Car className="w-5 h-5" />
          Generate Vehicle Cover
        </button>
      </form>
    </>
  );
}