import React, { useState } from 'react';
import { Tent } from 'lucide-react';
import { PageLayout } from '../../components/layout/PageLayout';
import { CoverTravelCampingForm } from '../../components/CoverTravelCampingForm';
import { ImagePreview } from '../../components/ImagePreview';

function CoverTravelCamping() {
  const [imageUrl, setImageUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  return (
    <PageLayout
      title="Cover - Travel & Camping"
      description="Create colorful and adventurous camping scenes for book covers!"
      icon={<Tent className="w-10 h-10 text-emerald-500" />}
      showBackButton={false}
    >
      <div className="p-6 space-y-8">
        <CoverTravelCampingForm 
          onImageGenerated={setImageUrl}
          setIsLoading={setIsLoading}
        />
        <ImagePreview imageUrl={imageUrl} isLoading={isLoading} />
      </div>
    </PageLayout>
  );
}

export default CoverTravelCamping;