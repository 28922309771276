import React, { useState } from 'react';
import { Flower } from 'lucide-react';
import { LoadingOverlay } from './LoadingOverlay';
import toast from 'react-hot-toast';
import { useImageGeneration } from '../hooks/useImageGeneration';

interface CoverFloralPatternFormProps {
  onImageGenerated: (imageUrl: string) => void;
  setIsLoading: (loading: boolean) => void;
}

const COLOR_THEMES = [
  'Garden',
  'Spring',
  'Summer',
  'Tropical',
  'Cottage',
  'Wildflower'
] as const;

const STYLE_OPTIONS = [
  'Watercolor',
  'Botanical',
  'Folk Art',
  'Modern',
  'Vintage',
  'Abstract'
] as const;

const FLOWER_SUGGESTIONS = [
  'roses and peonies',
  'wildflowers and daisies',
  'tulips and lilies',
  'sunflowers and lavender',
  'cherry blossoms',
  'tropical flowers'
] as const;

export function CoverFloralPatternForm({ onImageGenerated, setIsLoading }: CoverFloralPatternFormProps) {
  const [flowers, setFlowers] = useState('');
  const [arrangement, setArrangement] = useState('');
  const [colorTheme, setColorTheme] = useState<string>(COLOR_THEMES[0]);
  const [style, setStyle] = useState<string>(STYLE_OPTIONS[0]);
  const [isGenerating, setIsGenerating] = useState(false);
  const { generateWithCredits } = useImageGeneration();

  const handleGenerateImage = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!flowers.trim() || !arrangement.trim()) {
      toast.error('Please fill in all fields');
      return;
    }

    setIsGenerating(true);
    setIsLoading(true);
    try {
      const prompt = `Create a colorful ${colorTheme.toLowerCase()} themed ${style.toLowerCase()} floral pattern featuring ${flowers} arranged in ${arrangement}. The design should be lush and beautiful with delicate details. Use vibrant colors and a refined style perfect for a book cover. The pattern should be harmonious and balanced with natural flow. Make it colorful and visually striking with botanical beauty and artistic composition.`;
      
      const imageUrl = await generateWithCredits(prompt);
      if (imageUrl) {
        onImageGenerated(imageUrl);
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('Failed to generate image');
    } finally {
      setIsGenerating(false);
      setIsLoading(false);
    }
  };

  return (
    <>
      {isGenerating && <LoadingOverlay />}
      <form onSubmit={handleGenerateImage} className="space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <label htmlFor="flowers" className="block text-sm font-medium text-gray-700 mb-1">
              Flower Types
            </label>
            <input
              id="flowers"
              type="text"
              value={flowers}
              onChange={(e) => setFlowers(e.target.value)}
              placeholder="e.g., roses and peonies"
              className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-red-400 focus:border-red-400"
              required
            />
            <div className="mt-2 flex flex-wrap gap-2">
              {FLOWER_SUGGESTIONS.map((suggestion) => (
                <button
                  key={suggestion}
                  type="button"
                  onClick={() => setFlowers(suggestion)}
                  className="px-3 py-1 text-sm bg-red-50 text-red-700 rounded-full hover:bg-red-100 transition-colors"
                >
                  {suggestion}
                </button>
              ))}
            </div>
          </div>
          <div>
            <label htmlFor="arrangement" className="block text-sm font-medium text-gray-700 mb-1">
              Pattern Arrangement
            </label>
            <input
              id="arrangement"
              type="text"
              value={arrangement}
              onChange={(e) => setArrangement(e.target.value)}
              placeholder="e.g., flowing vines, scattered bouquets"
              className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-red-400 focus:border-red-400"
              required
            />
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Color Theme
          </label>
          <div className="grid grid-cols-2 sm:grid-cols-3 gap-2">
            {COLOR_THEMES.map((theme) => (
              <button
                key={theme}
                type="button"
                onClick={() => setColorTheme(theme)}
                className={`px-4 py-2 rounded-lg text-sm font-medium transition-colors ${
                  colorTheme === theme
                    ? 'bg-red-100 text-red-700 border-2 border-red-400'
                    : 'bg-gray-50 text-gray-700 border border-gray-300 hover:bg-gray-100'
                }`}
              >
                {theme}
              </button>
            ))}
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Art Style
          </label>
          <div className="grid grid-cols-2 sm:grid-cols-3 gap-2">
            {STYLE_OPTIONS.map((option) => (
              <button
                key={option}
                type="button"
                onClick={() => setStyle(option)}
                className={`px-4 py-2 rounded-lg text-sm font-medium transition-colors ${
                  style === option
                    ? 'bg-red-100 text-red-700 border-2 border-red-400'
                    : 'bg-gray-50 text-gray-700 border border-gray-300 hover:bg-gray-100'
                }`}
              >
                {option}
              </button>
            ))}
          </div>
        </div>

        <button
          type="submit"
          className="w-full bg-red-500 hover:bg-red-600 text-white font-semibold py-3 px-6 rounded-lg transition duration-200 flex items-center justify-center gap-2"
        >
          <Flower className="w-5 h-5" />
          Generate Floral Cover
        </button>
      </form>
    </>
  );
}