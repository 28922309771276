import React, { useState } from 'react';
import { PenTool } from 'lucide-react';
import { PageLayout } from '../../components/layout/PageLayout';
import { CoverCursiveArtForm } from '../../components/CoverCursiveArtForm';
import { ImagePreview } from '../../components/ImagePreview';

function CoverCursiveArt() {
  const [imageUrl, setImageUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  return (
    <PageLayout
      title="Cover - Cursive Art"
      description="Create colorful and elegant cursive text designs for book covers!"
      icon={<PenTool className="w-10 h-10 text-lime-500" />}
      showBackButton={false}
    >
      <div className="p-6 space-y-8">
        <CoverCursiveArtForm 
          onImageGenerated={setImageUrl}
          setIsLoading={setIsLoading}
        />
        <ImagePreview imageUrl={imageUrl} isLoading={isLoading} />
      </div>
    </PageLayout>
  );
}

export default CoverCursiveArt;