import React, { useState } from 'react';
import { Type } from 'lucide-react';
import { PageLayout } from '../components/layout/PageLayout';
import { TitleGeneratorForm } from '../components/TitleGeneratorForm';
import { ImagePreview } from '../components/ImagePreview';

function TitleGenerator() {
  const [imageUrl, setImageUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  return (
    <PageLayout
      title="Title Generator"
      description="Create beautiful titles for your coloring books!"
      icon={<Type className="w-10 h-10 text-violet-500" />}
      showBackButton={false}
    >
      <div className="p-6 space-y-8">
        <TitleGeneratorForm 
          onImageGenerated={setImageUrl}
          setIsLoading={setIsLoading}
        />
        <ImagePreview imageUrl={imageUrl} isLoading={isLoading} />
      </div>
    </PageLayout>
  );
}

export default TitleGenerator;