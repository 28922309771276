import { AZURE_CONFIG } from './config';

export async function generateImage(prompt: string): Promise<string> {
  try {
    if (!AZURE_CONFIG.apiKey) {
      throw new Error('Azure API key is not configured');
    }

    const response = await fetch(`${AZURE_CONFIG.endpoint}/openai/deployments/${AZURE_CONFIG.deployments.dalle}/images/generations?api-version=${AZURE_CONFIG.apiVersion}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'api-key': AZURE_CONFIG.apiKey,
      },
      body: JSON.stringify({
        prompt,
        n: 1,
        size: '1024x1024',
        quality: 'standard',
        style: 'vivid',
        temperature: 0.2
      }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      console.error('Azure API Error:', errorData);
      throw new Error(errorData.error?.message || `Failed to generate image: ${response.statusText}`);
    }

    const data = await response.json();
    if (!data.data?.[0]?.url) {
      throw new Error('No image URL received from Azure');
    }

    if (!data.data[0].url) {
      throw new Error('Invalid response from Azure API');
    }

    return data.data[0].url;
  } catch (error) {
    const errorMessage = error instanceof Error 
      ? `Image generation failed: ${error.message}`
      : 'An unexpected error occurred during image generation';
    console.error('Image generation error:', errorMessage);
    throw new Error(errorMessage);
  }
}