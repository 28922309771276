import React, { useState } from 'react';
import { Tent } from 'lucide-react';
import { LoadingOverlay } from './LoadingOverlay';
import toast from 'react-hot-toast';
import { useImageGeneration } from '../hooks/useImageGeneration';

interface CoverTravelCampingFormProps {
  onImageGenerated: (imageUrl: string) => void;
  setIsLoading: (loading: boolean) => void;
}

const COLOR_THEMES = [
  'Sunset',
  'Forest',
  'Mountain',
  'Lake',
  'Adventure',
  'Nature'
] as const;

const STYLE_OPTIONS = [
  'Watercolor',
  'Cartoon',
  'Minimalist',
  'Vintage',
  'Modern',
  'Rustic'
] as const;

const SCENE_SUGGESTIONS = [
  'mountain campsite',
  'lakeside tent',
  'forest clearing',
  'riverside camp',
  'beach camping',
  'desert oasis'
] as const;

export function CoverTravelCampingForm({ onImageGenerated, setIsLoading }: CoverTravelCampingFormProps) {
  const [scene, setScene] = useState('');
  const [activity, setActivity] = useState('');
  const [colorTheme, setColorTheme] = useState<string>(COLOR_THEMES[0]);
  const [style, setStyle] = useState<string>(STYLE_OPTIONS[0]);
  const [isGenerating, setIsGenerating] = useState(false);
  const { generateWithCredits } = useImageGeneration();

  const handleGenerateImage = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!scene.trim() || !activity.trim()) {
      toast.error('Please fill in all fields');
      return;
    }

    setIsGenerating(true);
    setIsLoading(true);
    try {
      const prompt = `Create a colorful ${colorTheme.toLowerCase()} themed ${style.toLowerCase()} illustration of a cozy ${scene} scene with people ${activity}. The design should capture the spirit of outdoor adventure and camping life. Use vibrant colors and a clean style perfect for a book cover. Include natural elements and camping equipment that create an inviting atmosphere. Make it colorful and appealing with a sense of wanderlust and exploration.`;
      
      const imageUrl = await generateWithCredits(prompt);
      if (imageUrl) {
        onImageGenerated(imageUrl);
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('Failed to generate image');
    } finally {
      setIsGenerating(false);
      setIsLoading(false);
    }
  };

  return (
    <>
      {isGenerating && <LoadingOverlay />}
      <form onSubmit={handleGenerateImage} className="space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <label htmlFor="scene" className="block text-sm font-medium text-gray-700 mb-1">
              Camping Scene
            </label>
            <input
              id="scene"
              type="text"
              value={scene}
              onChange={(e) => setScene(e.target.value)}
              placeholder="e.g., mountain campsite, lakeside tent"
              className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-emerald-500 focus:border-emerald-500"
              required
            />
            <div className="mt-2 flex flex-wrap gap-2">
              {SCENE_SUGGESTIONS.map((suggestion) => (
                <button
                  key={suggestion}
                  type="button"
                  onClick={() => setScene(suggestion)}
                  className="px-3 py-1 text-sm bg-emerald-50 text-emerald-700 rounded-full hover:bg-emerald-100 transition-colors"
                >
                  {suggestion}
                </button>
              ))}
            </div>
          </div>
          <div>
            <label htmlFor="activity" className="block text-sm font-medium text-gray-700 mb-1">
              Camping Activity
            </label>
            <input
              id="activity"
              type="text"
              value={activity}
              onChange={(e) => setActivity(e.target.value)}
              placeholder="e.g., roasting marshmallows, hiking"
              className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-emerald-500 focus:border-emerald-500"
              required
            />
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Color Theme
          </label>
          <div className="grid grid-cols-2 sm:grid-cols-3 gap-2">
            {COLOR_THEMES.map((theme) => (
              <button
                key={theme}
                type="button"
                onClick={() => setColorTheme(theme)}
                className={`px-4 py-2 rounded-lg text-sm font-medium transition-colors ${
                  colorTheme === theme
                    ? 'bg-emerald-100 text-emerald-700 border-2 border-emerald-500'
                    : 'bg-gray-50 text-gray-700 border border-gray-300 hover:bg-gray-100'
                }`}
              >
                {theme}
              </button>
            ))}
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Art Style
          </label>
          <div className="grid grid-cols-2 sm:grid-cols-3 gap-2">
            {STYLE_OPTIONS.map((option) => (
              <button
                key={option}
                type="button"
                onClick={() => setStyle(option)}
                className={`px-4 py-2 rounded-lg text-sm font-medium transition-colors ${
                  style === option
                    ? 'bg-emerald-100 text-emerald-700 border-2 border-emerald-500'
                    : 'bg-gray-50 text-gray-700 border border-gray-300 hover:bg-gray-100'
                }`}
              >
                {option}
              </button>
            ))}
          </div>
        </div>

        <button
          type="submit"
          className="w-full bg-emerald-600 hover:bg-emerald-700 text-white font-semibold py-3 px-6 rounded-lg transition duration-200 flex items-center justify-center gap-2"
        >
          <Tent className="w-5 h-5" />
          Generate Camping Cover
        </button>
      </form>
    </>
  );
}