import React from 'react';
import { Outlet, Routes, Route } from 'react-router-dom';
import { CategoryLayout } from '../../components/layout/CategoryLayout';
import { Heart, Building2, Tent, Store, Briefcase } from 'lucide-react';
import CozyFriends from './CozyFriends';
import CoverCozyFriend from './CoverCozyFriend';
import CoverTravelCamping from './CoverTravelCamping';
import CoverCozyBuilding from './CoverCozyBuilding';
import CoverRoomCorner from './CoverRoomCorner';
import CozyBuilding from './CozyBuilding';
import TravelCamping from './TravelCamping';
import FunnyShop from './FunnyShop';
import AnimalProfession from './AnimalProfession';
import RoomCorner from './RoomCorner';

const tools = [
  {
    id: 'cover-friends',
    title: 'Cover - Cozy Friend',
    description: 'Create adorable cover designs with friendly pairs',
    icon: Heart,
    path: 'cover-friends'
  },
  {
    id: 'cover-building',
    title: 'Cover - Cozy Building',
    description: 'Create beautiful isometric building designs',
    icon: Building2,
    path: 'cover-building'
  },
  {
    id: 'cover-camping',
    title: 'Cover - Travel & Camping',
    description: 'Create colorful outdoor adventure scenes',
    icon: Tent,
    path: 'cover-camping'
  },
  {
    id: 'cover-room',
    title: 'Cover - Room Corner',
    description: 'Create colorful isometric room designs',
    icon: Building2,
    path: 'cover-room'
  },
  {
    id: 'friends',
    title: 'Cozy Friends',
    description: 'Design heartwarming animal friendship scenes',
    icon: Heart,
    path: 'friends'
  },
  {
    id: 'building',
    title: 'Cozy Building',
    description: 'Generate cozy architectural designs',
    icon: Building2,
    path: 'building'
  },
  {
    id: 'travel-camping',
    title: 'Travel & Camping',
    description: 'Create outdoor adventure scenes',
    icon: Tent,
    path: 'travel-camping'
  },
  {
    id: 'funny-shop',
    title: 'Scene at Shop',
    description: 'Design delightful shopping scenes',
    icon: Store,
    path: 'funny-shop'
  },
  {
    id: 'animal-profession',
    title: 'Animal Profession',
    description: 'Create professional animal characters',
    icon: Briefcase,
    path: 'animal-profession'
  },
  {
    id: 'room-corner',
    title: 'Room Corner',
    description: 'Design isometric room corners',
    icon: Building2,
    path: 'room-corner'
  }
];

function CozyLayout() {
  return (
    <CategoryLayout
      title="Cozy Collection"
      description="Warm and inviting scenes for all ages"
      icon={Heart}
      color="red"
      tools={tools}
    >
      <Routes>
        <Route path="cover-friends" element={<CoverCozyFriend />} />
        <Route path="cover-building" element={<CoverCozyBuilding />} />
        <Route path="cover-camping" element={<CoverTravelCamping />} />
        <Route path="cover-room" element={<CoverRoomCorner />} />
        <Route path="friends" element={<CozyFriends />} />
        <Route path="building" element={<CozyBuilding />} />
        <Route path="travel-camping" element={<TravelCamping />} />
        <Route path="funny-shop" element={<FunnyShop />} />
        <Route path="animal-profession" element={<AnimalProfession />} />
        <Route path="room-corner" element={<RoomCorner />} />
      </Routes>
    </CategoryLayout>
  );
}

export default CozyLayout;