import React, { useState } from 'react';
import { Smile } from 'lucide-react';
import { PageLayout } from '../../components/layout/PageLayout';
import { EmojiStyleForm } from '../../components/EmojiStyleForm';
import { ImagePreview } from '../../components/ImagePreview';

function EmojiStyle() {
  const [imageUrl, setImageUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  return (
    <PageLayout
      title="Emoji Style"
      description="Create adorable emoji-style coloring pages!"
      icon={<Smile className="w-10 h-10 text-yellow-500" />}
      showBackButton={false}
    >
      <div className="p-6 space-y-8">
        <EmojiStyleForm 
          onImageGenerated={setImageUrl}
          setIsLoading={setIsLoading}
        />
        <ImagePreview imageUrl={imageUrl} isLoading={isLoading} />
      </div>
    </PageLayout>
  );
}

export default EmojiStyle;