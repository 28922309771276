import React, { useState } from 'react';
import { Palette } from 'lucide-react';
import { LoadingOverlay } from './LoadingOverlay';
import toast from 'react-hot-toast';
import { useImageGeneration } from '../hooks/useImageGeneration';

interface CoverHolidayFormProps {
  onImageGenerated: (imageUrl: string) => void;
  setIsLoading: (loading: boolean) => void;
}

const FRAME_STYLES = [
  'Ornate',
  'Modern',
  'Vintage',
  'Minimalist',
  'Classic',
  'Decorative'
] as const;

const COLOR_SCHEMES = [
  'Traditional',
  'Modern',
  'Elegant',
  'Vibrant',
  'Rustic',
  'Whimsical'
] as const;

const SUGGESTED_HOLIDAYS = [
  'Christmas',
  'Valentine\'s Day',
  'Mother\'s Day',
  'Easter',
  'Halloween',
  'Thanksgiving'
] as const;

export function CoverHolidayForm({ onImageGenerated, setIsLoading }: CoverHolidayFormProps) {
  const [title, setTitle] = useState('');
  const [subtitle, setSubtitle] = useState('');
  const [holiday, setHoliday] = useState('');
  const [frameStyle, setFrameStyle] = useState<string>(FRAME_STYLES[0]);
  const [colorScheme, setColorScheme] = useState<string>(COLOR_SCHEMES[0]);
  const [isGenerating, setIsGenerating] = useState(false);
  const { generateWithCredits } = useImageGeneration();

  const handleGenerateImage = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!holiday.trim() || !title.trim()) {
      toast.error('Please fill in all required fields');
      return;
    }

    setIsGenerating(true);
    setIsLoading(true);
    try {
      const prompt = `Create a colorful ${holiday} themed coloring book cover design with a ${frameStyle.toLowerCase()} frame style in ${colorScheme.toLowerCase()} colors. The main title "${title}" should be prominently displayed${subtitle ? ` with the subtitle "${subtitle}"` : ''}. The design should feature traditional ${holiday} elements and decorative patterns typical of adult coloring books. Use a sophisticated and detailed style suitable for adult coloring enthusiasts.`;
      
      const imageUrl = await generateWithCredits(prompt);
      if (imageUrl) {
        onImageGenerated(imageUrl);
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('Failed to generate image');
    } finally {
      setIsGenerating(false);
      setIsLoading(false);
    }
  };

  return (
    <>
      {isGenerating && <LoadingOverlay />}
      <form onSubmit={handleGenerateImage} className="space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <label htmlFor="title" className="block text-sm font-medium text-gray-700 mb-1">
              Book Title
            </label>
            <input
              id="title"
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="e.g., Magical Christmas Patterns"
              className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-purple-500 focus:border-purple-500"
              required
            />
          </div>
          <div>
            <label htmlFor="subtitle" className="block text-sm font-medium text-gray-700 mb-1">
              Subtitle (Optional)
            </label>
            <input
              id="subtitle"
              type="text"
              value={subtitle}
              onChange={(e) => setSubtitle(e.target.value)}
              placeholder="e.g., Relaxing Holiday Designs"
              className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-purple-500 focus:border-purple-500"
            />
          </div>
        </div>

        <div>
          <label htmlFor="holiday" className="block text-sm font-medium text-gray-700 mb-1">
            Holiday Theme
          </label>
          <input
            id="holiday"
            type="text"
            value={holiday}
            onChange={(e) => setHoliday(e.target.value)}
            placeholder="e.g., Christmas, Valentine's Day"
            className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-purple-500 focus:border-purple-500 mb-2"
            required
          />
          <div className="flex flex-wrap gap-2">
            {SUGGESTED_HOLIDAYS.map((suggestion) => (
              <button
                key={suggestion}
                type="button"
                onClick={() => setHoliday(suggestion)}
                className="px-3 py-1 text-sm bg-purple-50 text-purple-700 rounded-full hover:bg-purple-100 transition-colors"
              >
                {suggestion}
              </button>
            ))}
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Frame Style
          </label>
          <div className="grid grid-cols-2 sm:grid-cols-3 gap-2">
            {FRAME_STYLES.map((style) => (
              <button
                key={style}
                type="button"
                onClick={() => setFrameStyle(style)}
                className={`px-4 py-2 rounded-lg text-sm font-medium transition-colors ${
                  frameStyle === style
                    ? 'bg-purple-100 text-purple-700 border-2 border-purple-500'
                    : 'bg-gray-50 text-gray-700 border border-gray-300 hover:bg-gray-100'
                }`}
              >
                {style}
              </button>
            ))}
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Color Scheme
          </label>
          <div className="grid grid-cols-2 sm:grid-cols-3 gap-2">
            {COLOR_SCHEMES.map((scheme) => (
              <button
                key={scheme}
                type="button"
                onClick={() => setColorScheme(scheme)}
                className={`px-4 py-2 rounded-lg text-sm font-medium transition-colors ${
                  colorScheme === scheme
                    ? 'bg-purple-100 text-purple-700 border-2 border-purple-500'
                    : 'bg-gray-50 text-gray-700 border border-gray-300 hover:bg-gray-100'
                }`}
              >
                {scheme}
              </button>
            ))}
          </div>
        </div>

        <button
          type="submit"
          className="w-full bg-purple-600 hover:bg-purple-700 text-white font-semibold py-3 px-6 rounded-lg transition duration-200 flex items-center justify-center gap-2"
        >
          <Palette className="w-5 h-5" />
          Generate Holiday Cover
        </button>
      </form>
    </>
  );
}