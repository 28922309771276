import React, { useState } from 'react';
import { Type } from 'lucide-react';
import { LoadingOverlay } from './LoadingOverlay';
import toast from 'react-hot-toast';
import { useImageGeneration } from '../hooks/useImageGeneration';

interface TitleGeneratorFormProps {
  onImageGenerated: (imageUrl: string) => void;
  setIsLoading: (loading: boolean) => void;
}

const STYLE_OPTIONS = [
  'Playful',
  'Elegant',
  'Bold',
  'Cute',
  'Modern',
  'Vintage'
] as const;

const COLOR_THEMES = [
  'Rainbow',
  'Pastel',
  'Vibrant',
  'Monochrome',
  'Neon',
  'Earthy'
] as const;

export function TitleGeneratorForm({ onImageGenerated, setIsLoading }: TitleGeneratorFormProps) {
  const [title, setTitle] = useState('');
  const [style, setStyle] = useState<string>(STYLE_OPTIONS[0]);
  const [colorTheme, setColorTheme] = useState<string>(COLOR_THEMES[0]);
  const [isGenerating, setIsGenerating] = useState(false);
  const { generateWithCredits } = useImageGeneration();

  const handleGenerateImage = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!title.trim()) {
      toast.error('Please enter a title');
      return;
    }

    setIsGenerating(true);
    setIsLoading(true);
    try {
      const prompt = `Create a ${style.toLowerCase()} ${colorTheme.toLowerCase()} colored title text design that says "${title}" on a pure white background. The text should be large, clear, and beautifully styled with decorative elements. Make it perfect for a coloring book cover. The design should be centered with ample white space around it. NO background elements or patterns, ONLY the title text with decorative flourishes.`;
      
      const imageUrl = await generateWithCredits(prompt);
      if (imageUrl) {
        onImageGenerated(imageUrl);
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('Failed to generate title');
    } finally {
      setIsGenerating(false);
      setIsLoading(false);
    }
  };

  return (
    <>
      {isGenerating && <LoadingOverlay />}
      <form onSubmit={handleGenerateImage} className="space-y-6">
        <div>
          <label htmlFor="title" className="block text-sm font-medium text-gray-700 mb-1">
            Enter Your Book Title
          </label>
          <input
            id="title"
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="e.g., Magical Unicorns, Ocean Adventures"
            className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-violet-500 focus:border-violet-500"
            required
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Title Style
          </label>
          <div className="grid grid-cols-2 sm:grid-cols-3 gap-2">
            {STYLE_OPTIONS.map((option) => (
              <button
                key={option}
                type="button"
                onClick={() => setStyle(option)}
                className={`px-4 py-2 rounded-lg text-sm font-medium transition-colors ${
                  style === option
                    ? 'bg-violet-100 text-violet-700 border-2 border-violet-500'
                    : 'bg-gray-50 text-gray-700 border border-gray-300 hover:bg-gray-100'
                }`}
              >
                {option}
              </button>
            ))}
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Color Theme
          </label>
          <div className="grid grid-cols-2 sm:grid-cols-3 gap-2">
            {COLOR_THEMES.map((theme) => (
              <button
                key={theme}
                type="button"
                onClick={() => setColorTheme(theme)}
                className={`px-4 py-2 rounded-lg text-sm font-medium transition-colors ${
                  colorTheme === theme
                    ? 'bg-violet-100 text-violet-700 border-2 border-violet-500'
                    : 'bg-gray-50 text-gray-700 border border-gray-300 hover:bg-gray-100'
                }`}
              >
                {theme}
              </button>
            ))}
          </div>
        </div>

        <button
          type="submit"
          className="w-full bg-violet-600 hover:bg-violet-700 text-white font-semibold py-3 px-6 rounded-lg transition duration-200 flex items-center justify-center gap-2"
        >
          <Type className="w-5 h-5" />
          Generate Title
        </button>
      </form>
    </>
  );
}