import React, { useState } from 'react';
import { Type } from 'lucide-react';
import { LoadingOverlay } from './LoadingOverlay';
import toast from 'react-hot-toast';
import { useImageGeneration } from '../hooks/useImageGeneration';

interface CoverDecorativeTextFormProps {
  onImageGenerated: (imageUrl: string) => void;
  setIsLoading: (loading: boolean) => void;
}

const COLOR_THEMES = [
  'Vibrant',
  'Pastel',
  'Rainbow',
  'Sunset',
  'Galaxy',
  'Nature'
] as const;

const STYLE_OPTIONS = [
  'Modern',
  'Vintage',
  'Playful',
  'Elegant',
  'Bold',
  'Artistic'
] as const;

const DECORATIVE_ELEMENTS = [
  'geometric shapes',
  'abstract patterns',
  'organic swirls',
  'dots and lines',
  'brush strokes',
  'ornamental frames'
] as const;

export function CoverDecorativeTextForm({ onImageGenerated, setIsLoading }: CoverDecorativeTextFormProps) {
  const [text, setText] = useState('');
  const [elements, setElements] = useState('');
  const [colorTheme, setColorTheme] = useState<string>(COLOR_THEMES[0]);
  const [style, setStyle] = useState<string>(STYLE_OPTIONS[0]);
  const [isGenerating, setIsGenerating] = useState(false);
  const { generateWithCredits } = useImageGeneration();

  const handleGenerateImage = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!text.trim() || !elements.trim()) {
      toast.error('Please fill in all fields');
      return;
    }

    setIsGenerating(true);
    setIsLoading(true);
    try {
      const prompt = `Create a colorful ${colorTheme.toLowerCase()} themed ${style.toLowerCase()} typography design featuring the text "${text}" decorated with ${elements}. The text should be bold and expressive with creative letter arrangements. Use vibrant colors and a dynamic style perfect for a book cover. The design should be eye-catching and balanced with harmonious decorative elements. Make it colorful and visually striking with innovative typography and artistic composition.`;
      
      const imageUrl = await generateWithCredits(prompt);
      if (imageUrl) {
        onImageGenerated(imageUrl);
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('Failed to generate image');
    } finally {
      setIsGenerating(false);
      setIsLoading(false);
    }
  };

  return (
    <>
      {isGenerating && <LoadingOverlay />}
      <form onSubmit={handleGenerateImage} className="space-y-6">
        <div>
          <label htmlFor="text" className="block text-sm font-medium text-gray-700 mb-1">
            Your Text
          </label>
          <textarea
            id="text"
            value={text}
            onChange={(e) => setText(e.target.value)}
            placeholder="e.g., Adventure Awaits, Creative Mind"
            className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-amber-500 focus:border-amber-500 min-h-[100px] resize-y"
            required
          />
        </div>

        <div>
          <label htmlFor="elements" className="block text-sm font-medium text-gray-700 mb-1">
            Decorative Elements
          </label>
          <input
            id="elements"
            type="text"
            value={elements}
            onChange={(e) => setElements(e.target.value)}
            placeholder="e.g., geometric shapes, abstract patterns"
            className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-amber-500 focus:border-amber-500"
            required
          />
          <div className="mt-2 flex flex-wrap gap-2">
            {DECORATIVE_ELEMENTS.map((suggestion) => (
              <button
                key={suggestion}
                type="button"
                onClick={() => setElements(suggestion)}
                className="px-3 py-1 text-sm bg-amber-50 text-amber-700 rounded-full hover:bg-amber-100 transition-colors"
              >
                {suggestion}
              </button>
            ))}
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Color Theme
          </label>
          <div className="grid grid-cols-2 sm:grid-cols-3 gap-2">
            {COLOR_THEMES.map((theme) => (
              <button
                key={theme}
                type="button"
                onClick={() => setColorTheme(theme)}
                className={`px-4 py-2 rounded-lg text-sm font-medium transition-colors ${
                  colorTheme === theme
                    ? 'bg-amber-100 text-amber-700 border-2 border-amber-500'
                    : 'bg-gray-50 text-gray-700 border border-gray-300 hover:bg-gray-100'
                }`}
              >
                {theme}
              </button>
            ))}
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Design Style
          </label>
          <div className="grid grid-cols-2 sm:grid-cols-3 gap-2">
            {STYLE_OPTIONS.map((option) => (
              <button
                key={option}
                type="button"
                onClick={() => setStyle(option)}
                className={`px-4 py-2 rounded-lg text-sm font-medium transition-colors ${
                  style === option
                    ? 'bg-amber-100 text-amber-700 border-2 border-amber-500'
                    : 'bg-gray-50 text-gray-700 border border-gray-300 hover:bg-gray-100'
                }`}
              >
                {option}
              </button>
            ))}
          </div>
        </div>

        <button
          type="submit"
          className="w-full bg-amber-600 hover:bg-amber-700 text-white font-semibold py-3 px-6 rounded-lg transition duration-200 flex items-center justify-center gap-2"
        >
          <Type className="w-5 h-5" />
          Generate Text Cover
        </button>
      </form>
    </>
  );
}