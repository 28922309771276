import React, { useState } from 'react';
import { PenTool } from 'lucide-react';
import { LoadingOverlay } from './LoadingOverlay';
import toast from 'react-hot-toast';
import { useImageGeneration } from '../hooks/useImageGeneration';

interface CoverDoodlePatternFormProps {
  onImageGenerated: (imageUrl: string) => void;
  setIsLoading: (loading: boolean) => void;
}

const COLOR_THEMES = [
  'Candy',
  'Rainbow',
  'Pastel',
  'Neon',
  'Primary',
  'Retro'
] as const;

const STYLE_OPTIONS = [
  'Kawaii',
  'Whimsical',
  'Playful',
  'Abstract',
  'Geometric',
  'Hand-drawn'
] as const;

const PATTERN_ELEMENTS = [
  'hearts and stars',
  'flowers and leaves',
  'animals and clouds',
  'swirls and dots',
  'food and treats',
  'music notes'
] as const;

export function CoverDoodlePatternForm({ onImageGenerated, setIsLoading }: CoverDoodlePatternFormProps) {
  const [theme, setTheme] = useState('');
  const [elements, setElements] = useState('');
  const [colorTheme, setColorTheme] = useState<string>(COLOR_THEMES[0]);
  const [style, setStyle] = useState<string>(STYLE_OPTIONS[0]);
  const [isGenerating, setIsGenerating] = useState(false);
  const { generateWithCredits } = useImageGeneration();

  const handleGenerateImage = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!theme.trim() || !elements.trim()) {
      toast.error('Please fill in all fields');
      return;
    }

    setIsGenerating(true);
    setIsLoading(true);
    try {
      const prompt = `Create a colorful ${colorTheme.toLowerCase()} themed ${style.toLowerCase()} doodle pattern featuring ${theme} with ${elements}. The design should be seamless and playful with a repeating pattern. Use vibrant colors and a clean style perfect for a book cover. Include cute and charming elements that create a delightful composition. Make it colorful and visually appealing with a sense of fun and creativity.`;
      
      const imageUrl = await generateWithCredits(prompt);
      if (imageUrl) {
        onImageGenerated(imageUrl);
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('Failed to generate image');
    } finally {
      setIsGenerating(false);
      setIsLoading(false);
    }
  };

  return (
    <>
      {isGenerating && <LoadingOverlay />}
      <form onSubmit={handleGenerateImage} className="space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <label htmlFor="theme" className="block text-sm font-medium text-gray-700 mb-1">
              Pattern Theme
            </label>
            <input
              id="theme"
              type="text"
              value={theme}
              onChange={(e) => setTheme(e.target.value)}
              placeholder="e.g., space adventure, underwater world"
              className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-teal-500 focus:border-teal-500"
              required
            />
          </div>
          <div>
            <label htmlFor="elements" className="block text-sm font-medium text-gray-700 mb-1">
              Pattern Elements
            </label>
            <input
              id="elements"
              type="text"
              value={elements}
              onChange={(e) => setElements(e.target.value)}
              placeholder="e.g., hearts and stars, swirls and dots"
              className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-teal-500 focus:border-teal-500"
              required
            />
            <div className="mt-2 flex flex-wrap gap-2">
              {PATTERN_ELEMENTS.map((suggestion) => (
                <button
                  key={suggestion}
                  type="button"
                  onClick={() => setElements(suggestion)}
                  className="px-3 py-1 text-sm bg-teal-50 text-teal-700 rounded-full hover:bg-teal-100 transition-colors"
                >
                  {suggestion}
                </button>
              ))}
            </div>
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Color Theme
          </label>
          <div className="grid grid-cols-2 sm:grid-cols-3 gap-2">
            {COLOR_THEMES.map((theme) => (
              <button
                key={theme}
                type="button"
                onClick={() => setColorTheme(theme)}
                className={`px-4 py-2 rounded-lg text-sm font-medium transition-colors ${
                  colorTheme === theme
                    ? 'bg-teal-100 text-teal-700 border-2 border-teal-500'
                    : 'bg-gray-50 text-gray-700 border border-gray-300 hover:bg-gray-100'
                }`}
              >
                {theme}
              </button>
            ))}
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Doodle Style
          </label>
          <div className="grid grid-cols-2 sm:grid-cols-3 gap-2">
            {STYLE_OPTIONS.map((option) => (
              <button
                key={option}
                type="button"
                onClick={() => setStyle(option)}
                className={`px-4 py-2 rounded-lg text-sm font-medium transition-colors ${
                  style === option
                    ? 'bg-teal-100 text-teal-700 border-2 border-teal-500'
                    : 'bg-gray-50 text-gray-700 border border-gray-300 hover:bg-gray-100'
                }`}
              >
                {option}
              </button>
            ))}
          </div>
        </div>

        <button
          type="submit"
          className="w-full bg-teal-600 hover:bg-teal-700 text-white font-semibold py-3 px-6 rounded-lg transition duration-200 flex items-center justify-center gap-2"
        >
          <PenTool className="w-5 h-5" />
          Generate Doodle Cover
        </button>
      </form>
    </>
  );
}