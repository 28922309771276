import React, { useState } from 'react';
import { Bird } from 'lucide-react';
import { LoadingOverlay } from './LoadingOverlay';
import toast from 'react-hot-toast';
import { useImageGeneration } from '../hooks/useImageGeneration';

interface CoverFlyingBirdsFormProps {
  onImageGenerated: (imageUrl: string) => void;
  setIsLoading: (loading: boolean) => void;
}

const COLOR_THEMES = [
  'Sunset',
  'Rainbow',
  'Pastel',
  'Vibrant',
  'Dreamy',
  'Nature'
] as const;

const SKY_BACKGROUNDS = [
  'mountain peaks',
  'fluffy clouds',
  'starry night',
  'aurora borealis',
  'city skyline',
  'forest canopy'
] as const;

export function CoverFlyingBirdsForm({ onImageGenerated, setIsLoading }: CoverFlyingBirdsFormProps) {
  const [birdType, setBirdType] = useState('');
  const [background, setBackground] = useState('');
  const [colorTheme, setColorTheme] = useState<string>(COLOR_THEMES[0]);
  const [isGenerating, setIsGenerating] = useState(false);
  const { generateWithCredits } = useImageGeneration();

  const handleGenerateImage = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!birdType.trim() || !background.trim()) {
      toast.error('Please fill in all fields');
      return;
    }

    setIsGenerating(true);
    setIsLoading(true);
    try {
      const prompt = `Create a colorful ${colorTheme.toLowerCase()} themed illustration of a majestic ${birdType} soaring through the sky with ${background} in the background. The bird should be graceful and dynamic, with beautiful wing details. Use vibrant colors and a clean, artistic style perfect for a children's book cover. The design should be inspiring and uplifting with a sense of freedom and adventure. Make it colorful and appealing to young children.`;
      
      const imageUrl = await generateWithCredits(prompt);
      if (imageUrl) {
        onImageGenerated(imageUrl);
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('Failed to generate image');
    } finally {
      setIsGenerating(false);
      setIsLoading(false);
    }
  };

  return (
    <>
      {isGenerating && <LoadingOverlay />}
      <form onSubmit={handleGenerateImage} className="space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <label htmlFor="birdType" className="block text-sm font-medium text-gray-700 mb-1">
              Type of Bird
            </label>
            <input
              id="birdType"
              type="text"
              value={birdType}
              onChange={(e) => setBirdType(e.target.value)}
              placeholder="e.g., eagle, dove, phoenix"
              className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-sky-500 focus:border-sky-500"
              required
            />
          </div>
          <div>
            <label htmlFor="background" className="block text-sm font-medium text-gray-700 mb-1">
              Sky Background
            </label>
            <input
              id="background"
              type="text"
              value={background}
              onChange={(e) => setBackground(e.target.value)}
              placeholder="e.g., mountain peaks, fluffy clouds"
              className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-sky-500 focus:border-sky-500"
              required
            />
            <div className="mt-2 flex flex-wrap gap-2">
              {SKY_BACKGROUNDS.map((suggestion) => (
                <button
                  key={suggestion}
                  type="button"
                  onClick={() => setBackground(suggestion)}
                  className="px-3 py-1 text-sm bg-sky-50 text-sky-700 rounded-full hover:bg-sky-100 transition-colors"
                >
                  {suggestion}
                </button>
              ))}
            </div>
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Color Theme
          </label>
          <div className="grid grid-cols-2 sm:grid-cols-3 gap-2">
            {COLOR_THEMES.map((theme) => (
              <button
                key={theme}
                type="button"
                onClick={() => setColorTheme(theme)}
                className={`px-4 py-2 rounded-lg text-sm font-medium transition-colors ${
                  colorTheme === theme
                    ? 'bg-sky-100 text-sky-700 border-2 border-sky-500'
                    : 'bg-gray-50 text-gray-700 border border-gray-300 hover:bg-gray-100'
                }`}
              >
                {theme}
              </button>
            ))}
          </div>
        </div>

        <button
          type="submit"
          className="w-full bg-sky-600 hover:bg-sky-700 text-white font-semibold py-3 px-6 rounded-lg transition duration-200 flex items-center justify-center gap-2"
        >
          <Bird className="w-5 h-5" />
          Generate Bird Cover
        </button>
      </form>
    </>
  );
}