import React, { useState } from 'react';
import { Bird } from 'lucide-react';
import { PageLayout } from '../../components/layout/PageLayout';
import { CoverFlyingBirdsForm } from '../../components/CoverFlyingBirdsForm';
import { ImagePreview } from '../../components/ImagePreview';

function CoverFlyingBirds() {
  const [imageUrl, setImageUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  return (
    <PageLayout
      title="Cover - Flying Birds"
      description="Create colorful and majestic flying bird designs for book covers!"
      icon={<Bird className="w-10 h-10 text-sky-500" />}
      showBackButton={false}
    >
      <div className="p-6 space-y-8">
        <CoverFlyingBirdsForm 
          onImageGenerated={setImageUrl}
          setIsLoading={setIsLoading}
        />
        <ImagePreview imageUrl={imageUrl} isLoading={isLoading} />
      </div>
    </PageLayout>
  );
}

export default CoverFlyingBirds;