import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { CategoryLayout } from '../../components/layout/CategoryLayout';
import { PenTool, Quote, Type, Shapes, Flower2 } from 'lucide-react';
import CoverDecorativeText from './CoverDecorativeText';
import CoverFloralPattern from './CoverFloralPattern';
import CoverDoodlePattern from './CoverDoodlePattern';
import CoverCursiveArt from './CoverCursiveArt';
import CoverQuotesDoodle from './CoverQuotesDoodle';
import CoverBubbleText from './CoverBubbleText';
import DoodlePattern from './DoodlePattern';
import QuotesDoodle from './QuotesDoodle';
import CursiveArt from './CursiveArt';
import DecorativeText from './DecorativeText';
import FloralPatterns from './FloralPatterns';
import DoodleAnything from './DoodleAnything';
import BubbleText from './BubbleText';

const tools = [
  {
    id: 'cover-pattern',
    title: 'Cover - Doodle Pattern',
    description: 'Create colorful doodle patterns',
    icon: PenTool,
    path: 'cover-pattern'
  },
  {
    id: 'cover-quotes',
    title: 'Cover - Quotes + Doodle',
    description: 'Create colorful quote designs',
    icon: Quote,
    path: 'cover-quotes'
  },
  {
    id: 'cover-cursive',
    title: 'Cover - Cursive Art',
    description: 'Create colorful cursive designs',
    icon: Type,
    path: 'cover-cursive'
  },
  {
    id: 'cover-decorative',
    title: 'Cover - Decorative Text',
    description: 'Create colorful text designs',
    icon: Type,
    path: 'cover-decorative'
  },
  {
    id: 'cover-floral',
    title: 'Cover - Floral Pattern',
    description: 'Create colorful floral patterns',
    icon: Flower2,
    path: 'cover-floral'
  },
  {
    id: 'cover-bubble',
    title: 'Cover - Bubble Text',
    description: 'Create colorful bubble text designs',
    icon: Type,
    path: 'cover-bubble'
  },
  {
    id: 'pattern',
    title: 'Doodle Pattern',
    description: 'Generate seamless doodle patterns',
    icon: PenTool,
    path: 'pattern'
  },
  {
    id: 'quotes',
    title: 'Quotes + Doodle',
    description: 'Create inspiring quote designs',
    icon: Quote,
    path: 'quotes'
  },
  {
    id: 'cursive',
    title: 'Cursive Art',
    description: 'Transform words into beautiful designs',
    icon: Type,
    path: 'cursive'
  },
  {
    id: 'decorative',
    title: 'Decorative Text',
    description: 'Design beautiful text arrangements',
    icon: Shapes,
    path: 'decorative'
  },
  {
    id: 'floral',
    title: 'Floral Patterns',
    description: 'Create beautiful floral designs',
    icon: Flower2,
    path: 'floral'
  },
  {
    id: 'bubble',
    title: 'Bubble Text',
    description: 'Create fun bubble letter designs',
    icon: Type,
    path: 'bubble'
  },
  {
    id: 'anything',
    title: 'Doodle Anything',
    description: 'Create charming doodle patterns',
    icon: PenTool,
    path: 'anything'
  }
];

function DoodleLayout() {
  return (
    <CategoryLayout
      title="Doodle & Art"
      description="Creative patterns and artistic designs"
      icon={PenTool}
      color="teal"
      tools={tools}
    >
      <Routes>
        <Route path="cover-pattern" element={<CoverDoodlePattern />} />
        <Route path="cover-quotes" element={<CoverQuotesDoodle />} />
        <Route path="cover-cursive" element={<CoverCursiveArt />} />
        <Route path="cover-decorative" element={<CoverDecorativeText />} />
        <Route path="cover-floral" element={<CoverFloralPattern />} />
        <Route path="cover-bubble" element={<CoverBubbleText />} />
        <Route path="pattern" element={<DoodlePattern />} />
        <Route path="quotes" element={<QuotesDoodle />} />
        <Route path="cursive" element={<CursiveArt />} />
        <Route path="decorative" element={<DecorativeText />} />
        <Route path="floral" element={<FloralPatterns />} />
        <Route path="bubble" element={<BubbleText />} />
        <Route path="anything" element={<DoodleAnything />} />
      </Routes>
    </CategoryLayout>
  );
}

export default DoodleLayout;