import React, { useState } from 'react';
import { Landmark } from 'lucide-react';
import { PageLayout } from '../../components/layout/PageLayout';
import { ArchitecturalDetailsForm } from '../../components/ArchitecturalDetailsForm';
import { ImagePreview } from '../../components/ImagePreview';

function ArchitecturalDetails() {
  const [imageUrl, setImageUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  return (
    <PageLayout
      title="Architectural Details"
      description="Create intricate architectural elements for detailed coloring!"
      icon={<Landmark className="w-10 h-10 text-purple-500" />}
      showBackButton={false}
    >
      <div className="p-6 space-y-8">
        <ArchitecturalDetailsForm 
          onImageGenerated={setImageUrl}
          setIsLoading={setIsLoading}
        />
        <ImagePreview imageUrl={imageUrl} isLoading={isLoading} />
      </div>
    </PageLayout>
  );
}

export default ArchitecturalDetails;