import React, { useState } from 'react';
import { Cookie } from 'lucide-react';
import { LoadingOverlay } from './LoadingOverlay';
import toast from 'react-hot-toast';
import { useImageGeneration } from '../hooks/useImageGeneration';

interface CoverCuteFoodFormProps {
  onImageGenerated: (imageUrl: string) => void;
  setIsLoading: (loading: boolean) => void;
}

const COLOR_THEMES = [
  'Sweet',
  'Fresh',
  'Vibrant',
  'Pastel',
  'Kawaii',
  'Candy'
] as const;

const FOOD_MOODS = [
  'Happy',
  'Cheerful',
  'Playful',
  'Excited',
  'Silly',
  'Adorable'
] as const;

const BACKGROUND_SUGGESTIONS = [
  'kitchen',
  'cafe',
  'picnic',
  'bakery',
  'garden party',
  'restaurant'
] as const;

export function CoverCuteFoodForm({ onImageGenerated, setIsLoading }: CoverCuteFoodFormProps) {
  const [foodType, setFoodType] = useState('');
  const [background, setBackground] = useState('');
  const [colorTheme, setColorTheme] = useState<string>(COLOR_THEMES[0]);
  const [mood, setMood] = useState<string>(FOOD_MOODS[0]);
  const [isGenerating, setIsGenerating] = useState(false);
  const { generateWithCredits } = useImageGeneration();

  const handleGenerateImage = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!foodType.trim() || !background.trim()) {
      toast.error('Please fill in all fields');
      return;
    }

    setIsGenerating(true);
    setIsLoading(true);
    try {
      const prompt = `Create a colorful ${colorTheme.toLowerCase()} themed illustration of a ${mood.toLowerCase()} ${foodType} character with a cute face and expressive features, set in a delightful ${background} scene. The food should have big friendly eyes and a warm smile. Use vibrant colors and a clean, cartoon style perfect for a children's book cover. The design should be appetizing and playful with simple details that complement the main character. Make it colorful and appealing to young children.`;
      
      const imageUrl = await generateWithCredits(prompt);
      if (imageUrl) {
        onImageGenerated(imageUrl);
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('Failed to generate image');
    } finally {
      setIsGenerating(false);
      setIsLoading(false);
    }
  };

  return (
    <>
      {isGenerating && <LoadingOverlay />}
      <form onSubmit={handleGenerateImage} className="space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <label htmlFor="foodType" className="block text-sm font-medium text-gray-700 mb-1">
              Type of Food
            </label>
            <input
              id="foodType"
              type="text"
              value={foodType}
              onChange={(e) => setFoodType(e.target.value)}
              placeholder="e.g., cupcake, pizza, sushi"
              className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-emerald-500 focus:border-emerald-500"
              required
            />
          </div>
          <div>
            <label htmlFor="background" className="block text-sm font-medium text-gray-700 mb-1">
              Background Scene
            </label>
            <input
              id="background"
              type="text"
              value={background}
              onChange={(e) => setBackground(e.target.value)}
              placeholder="e.g., kitchen, cafe, picnic"
              className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-emerald-500 focus:border-emerald-500"
              required
            />
            <div className="mt-2 flex flex-wrap gap-2">
              {BACKGROUND_SUGGESTIONS.map((suggestion) => (
                <button
                  key={suggestion}
                  type="button"
                  onClick={() => setBackground(suggestion)}
                  className="px-3 py-1 text-sm bg-emerald-50 text-emerald-700 rounded-full hover:bg-emerald-100 transition-colors"
                >
                  {suggestion}
                </button>
              ))}
            </div>
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Color Theme
          </label>
          <div className="grid grid-cols-2 sm:grid-cols-3 gap-2">
            {COLOR_THEMES.map((theme) => (
              <button
                key={theme}
                type="button"
                onClick={() => setColorTheme(theme)}
                className={`px-4 py-2 rounded-lg text-sm font-medium transition-colors ${
                  colorTheme === theme
                    ? 'bg-emerald-100 text-emerald-700 border-2 border-emerald-500'
                    : 'bg-gray-50 text-gray-700 border border-gray-300 hover:bg-gray-100'
                }`}
              >
                {theme}
              </button>
            ))}
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Food's Mood
          </label>
          <div className="grid grid-cols-2 sm:grid-cols-3 gap-2">
            {FOOD_MOODS.map((option) => (
              <button
                key={option}
                type="button"
                onClick={() => setMood(option)}
                className={`px-4 py-2 rounded-lg text-sm font-medium transition-colors ${
                  mood === option
                    ? 'bg-emerald-100 text-emerald-700 border-2 border-emerald-500'
                    : 'bg-gray-50 text-gray-700 border border-gray-300 hover:bg-gray-100'
                }`}
              >
                {option}
              </button>
            ))}
          </div>
        </div>

        <button
          type="submit"
          className="w-full bg-emerald-600 hover:bg-emerald-700 text-white font-semibold py-3 px-6 rounded-lg transition duration-200 flex items-center justify-center gap-2"
        >
          <Cookie className="w-5 h-5" />
          Generate Food Cover
        </button>
      </form>
    </>
  );
}